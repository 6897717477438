import React, { useState } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import { AppLink } from "@base";
import { Icon } from "@atoms";

const DropdownNav = ({ menu, links, headingLink }) => {
  const [children, setChildren] = useState([]);
  const [offset, setOffset] = useState(0);

  return (
    <m.div
      variants={menu}
      className="z-90 pointer-events-none absolute top-[100%] flex rounded border border-gray bg-white opacity-0"
      onAnimationComplete={() => {
        setChildren([]);
      }}
    >
      <m.ul>
        {headingLink?.url && (
          <li className={clsx("flex flex-col overflow-hidden")}>
            <AppLink
              to={headingLink?.url}
              className="group flex h-12 flex-grow flex-col whitespace-nowrap p-3 py-4 text-sm font-bold hover:bg-gray-light"
            >
              <span className="underline decoration-orange underline-offset-4">
                {headingLink?.text}
              </span>
            </AppLink>
          </li>
        )}
        {links.map((link, i) => {
          if (link) {
            return (
              <m.li
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={clsx(
                  "flex items-center overflow-hidden border-t border-gray"
                )}
                onHoverStart={() => {
                  setChildren(link?.children || []);
                  setOffset(i);
                }}
              >
                <AppLink
                  to={link.url}
                  className="group flex h-10 flex-grow whitespace-nowrap p-3 text-sm font-bold hover:bg-gray-light"
                >
                  <span>{link.title || link.text}</span>
                  {!!link?.children?.length && (
                    <Icon
                      name="triangle"
                      className="trasition h-4 w-4 rotate-90 duration-300"
                    />
                  )}
                </AppLink>
              </m.li>
            );
          }
          return null;
        })}
      </m.ul>
      <m.ul
        className="border-l border-gray"
        style={{
          paddingTop: `calc(${(10 / 4) * offset + 3}rem + ${1 + offset}px)`,
        }}
      >
        {children?.map((c, i) => (
          <m.li
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={clsx("flex flex-col overflow-hidden", {
              "border-t border-gray": i > 0,
            })}
          >
            <AppLink
              to={c.url}
              className="group flex h-10 flex-grow flex-col whitespace-nowrap p-3 text-sm hover:bg-gray-light"
            >
              {c.title}
            </AppLink>
          </m.li>
        ))}
      </m.ul>
    </m.div>
  );
};

export default DropdownNav;
