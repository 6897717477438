import React from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import { useAppState } from "@state";

const FavoriteToggle = ({ storefrontId }) => {
  const [{ user }, dispatch] = useAppState();
  return (
    <button
      onClick={() => {
        // send to state first, just in case it takes a while
        // to update
        if (!user.favorites.includes(storefrontId)) {
          user.favorites?.push(storefrontId);
        } else {
          user.favorites = user.favorites?.filter(i => i !== storefrontId);
        }

        dispatch({
          type: "setFavorites",
          favorites: user.favorites,
        });

        // then send to the api
        fetch("/api/shopify/toggle-favorite", {
          method: "POST",
          body: JSON.stringify({
            token: user.token,
            product_id: storefrontId,
          }),
        })
          .then(res => res.json())
          .then(res => {
            if (!res.error) {
              try {
                dispatch({
                  type: "setFavorites",
                  favorites: JSON.parse(res.data.meta.value),
                });
              } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
              }
            } else {
              // eslint-disable-next-line no-console
              console.log(res.error);
            }
          });
      }}
      type="button"
      className="group absolute left-0 top-0 z-10 p-2"
    >
      <span className="sr-only">favorite</span>
      <Icon
        name="heart"
        className={clsx(
          "h-5 w-5 stroke-orange stroke-2 group-hover:text-orange",
          {
            "text-transparent": !user.favorites?.includes(storefrontId),
            "text-orange": user.favorites?.includes(storefrontId),
          }
        )}
      />
    </button>
  );
};

export default FavoriteToggle;
