import React from "react";
import { AppLink } from "@base";
import { Image } from "@atoms";

const CardProductSimple = ({ productLine }) => {
  return (
    <AppLink
      to={productLine.url}
      className="group relative z-10 flex w-full flex-grow flex-col overflow-hidden rounded border border-orange text-black"
    >
      <div className="bg-white p-2">
        <div className="relative aspect-[3/2] w-full flex-grow">
          <Image
            image={productLine.image || { url: "/png/icon.png" }}
            fill
            objectFit="contain"
          />
        </div>
      </div>
      <div className="flex flex-grow flex-col gap-3 bg-offwhite p-6 duration-300 group-hover:bg-orange group-hover:text-white">
        <h3 className="font-serif text-xl md:text-2xl">{productLine.title}</h3>
        <p className="text-sm leading-tight">{productLine.description}</p>
      </div>
    </AppLink>
  );
};

export default CardProductSimple;
