const appName = process.env.GATSBY_APP_NAME || "_";

const hasDoc = typeof document !== "undefined";

const cookieToObj = () => {
  if (hasDoc) {
    const obj = document.cookie.split(";").reduce((a, b) => {
      const [k, v] = b.split("=");
      return { ...a, [k.replace(/^\s/, "")]: decodeURI(v) };
    }, {});
    return obj;
  }
  return {};
};

export const getCookie = k => {
  const value = cookieToObj()[`${appName}_${k}`] || cookieToObj()[k];
  return value;
};

export const setCookie = (k, v = true, e = 365) => {
  if (hasDoc) {
    const date = new Date();
    date.setTime(date.getTime() + e * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${appName}_${k}=${v}; ${expires}; path=/`;
  }
};

export const hasCookie = (k, v, e = 365, reset = false) => {
  if (hasDoc) {
    // console.log(cookieToObj()[`${appName}_${k}`], v);
    if (cookieToObj()[`${appName}_${k}`] === v) {
      if (reset) {
        setCookie(k, v, e);
      }
      return true;
    }
  }
  return false;
};

export default { hasCookie, setCookie, getCookie };
