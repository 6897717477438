import React, { useState } from "react";
import clsx from "clsx";
import { AnimatePresence, m } from "framer-motion";
import { Icon } from "@atoms";

const Notification = ({ type, message, dismissable }) => {
  const [show, setShow] = useState(true);
  return (
    <AnimatePresence>
      {show && (
        <m.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ type: "tween" }}
          className={clsx(
            "w-full overflow-hidden rounded border border-current",
            {
              "text-orange": type === "info",
              "text-green": type === "success",
              "text-red-dark": type === "error",
            }
          )}
        >
          <div className="relative flex w-full gap-2 p-5 text-sm">
            <div className="flex w-6 flex-shrink-0 justify-end">
              <Icon name="alert" className="ml-auto h-6 w-6 p-1 text-current" />
            </div>
            <div className="mt-1 flex-grow">
              {type === "info" && <span className="mr-1 font-bold">Note:</span>}
              {type === "success" && (
                <span className="mr-1 font-bold">Success:</span>
              )}
              {type === "error" && (
                <span className="mr-1 font-bold">Error:</span>
              )}
              {message}
            </div>
            {dismissable && (
              <div className="flex w-6 flex-shrink-0 justify-end">
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  type="button"
                >
                  <span className="sr-only">close</span>
                  <Icon
                    name="close"
                    className="ml-auto h-6 w-6 p-1 text-current"
                  />
                </button>
              </div>
            )}
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};

Notification.defaultProps = {
  type: "info",
  dismissable: true,
};

export default Notification;
