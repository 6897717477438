import React, { useState } from "react";
import { Icon } from "@atoms";
import { AppLink } from "@base";
import { m } from "framer-motion";
import { useAppState } from "@state";

const DropdownAccount = ({ user }) => {
  const [, dispatch] = useAppState();
  const container = {
    hidden: {},
    show: {},
  };
  const menu = {
    hidden: { opacity: 0, pointerEvents: "none", y: "1rem" },
    show: { opacity: 1, pointerEvents: "auto", y: 0 },
  };
  const [animation, setAnimation] = useState("hidden");

  const classes =
    "inline-block w-full p-3 text-sm font-bold hover:bg-gray-light";

  return (
    <m.li
      key="accountHeader"
      className="relative block"
      variants={container}
      initial="hidden"
      animate={animation}
      onHoverStart={() => {
        setAnimation("show");
      }}
      onHoverEnd={() => {
        setAnimation("hidden");
      }}
      onClick={() => {
        setAnimation(s => {
          if (s === "hidden") return "show";
          return "hidden";
        });
      }}
    >
      <div>
        {/* account button trigger */}
        <button
          className="flex gap-0.5 py-3"
          aria-label="Open header account dropdown"
          type="button"
        >
          <Icon name="account" className="w-4 text-orange" />
          <Icon
            name="triangle"
            className="trasition h-4 w-4 rotate-180 text-orange duration-300"
          />
          <span className="sr-only">account</span>
        </button>

        <m.ul
          variants={menu}
          className="pointer-events-none absolute top-[100%] flex flex-col divide-y rounded border border-gray bg-white opacity-0"
        >
          {/* log in */}
          {!user.type && (
            <>
              <li>
                <AppLink className={classes} to="/login/">
                  Log In
                </AppLink>
              </li>
              <li>
                <AppLink className={classes} to="/register/">
                  Register
                </AppLink>
              </li>
            </>
          )}

          {user.type && (
            <>
              <li>
                <AppLink className={classes} to="/account">
                  Account
                </AppLink>
              </li>
              <li>
                <AppLink
                  className={classes}
                  onClick={() => dispatch({ type: "logout" })}
                >
                  Logout
                </AppLink>
              </li>
            </>
          )}
        </m.ul>
      </div>
    </m.li>
  );
};

DropdownAccount.defaultProps = {};

export default DropdownAccount;
