import React from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  to,
  children,
  className: _className,
  inverse,
  reverse,
  icon,
  download,
  onClick,
}) => {
  return (
    <AppLink
      to={to}
      download={download}
      onClick={onClick}
      className={clsx("inline-flex items-center", _className)}
    >
      {/* back arrow */}
      {icon && reverse && (
        <Icon name={icon || "chevron"} className="flex-shink-0 ml-1 h-4 w-4" />
      )}
      {/* label */}
      <span
        className={clsx(
          "text-xs font-bold leading-normal underline decoration-2 underline-offset-2 group-hover:decoration-current",
          {
            "decoration-white": inverse,
            "decoration-orange": !inverse,
          }
        )}
      >
        {children}
      </span>
      {/* forward arrow */}
      {icon && !reverse && (
        <Icon name={icon || "chevron"} className="flex-shink-0 ml-1 h-4 w-4" />
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  icon: false,
};

export default ButtonAlt;
