import camelCase from "lodash.camelcase";

const resolveCollection = c => {
  const images =
    c.media
      ?.filter(m => m.mediaContentType === "IMAGE")
      ?.map(m => ({
        ...m.image,
        url: m.image.url
          // remove strings added to duplicate images
          .replace(
            /_[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
            ""
          )
          .replace(/\?v=.*/, ""),
      }))
      // filter for unique images
      .reduce((agg, img) => {
        // console.log(image.url);
        if (agg.map(m => m.url).includes(img.url)) {
          return agg;
        }
        return [...agg, img];
      }, []) || [];
  return {
    ...c,
    ...c?.metafields?.reduce(
      (agg, meta) => ({
        ...agg,
        [camelCase(meta.key)]: meta.value,
      }),
      {}
    ),
    images,
    meta: {
      metaPageTitle: c?.seo?.title || `${c.title} | Kan Herb Company`,
      metaDescription:
        c?.seo?.description || c.description?.replace(/(<([^>]+)>|\n)/gi, ""),
      metaFeaturedImage: images[0] ? { url: images[0].url } : undefined,
    },
  };
};

export default resolveCollection;
