import React from "react";
import { Image } from "@atoms";

const Loading = () => {
  return (
    <div className="flex flex-grow animate-pulse flex-col items-center justify-center gap-1 font-bold text-orange">
      <div className="relative h-20 w-20 text-green">
        <Image image={{ url: "/png/icon.png" }} fill objectFit="contain" />
      </div>
      <span>loading</span>
    </div>
  );
};

export default Loading;
