const reducer = (state, action) => {
  switch (action.type) {
    case "setFilters":
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return state;
  }
};

const initialState = {
  filters: null,
};

export default {
  reducer,
  initialState,
};
