import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query HeaderQuery {
    craft {
      entry(section: "header") {
        ... on Craft_header_header_Entry {
          headerLinks {
            ... on Craft_headerLinks_productsDropdown_BlockType {
              uid
              type: typeHandle
              text
              productLinks {
                ... on Craft_productLinks_TableRow {
                  collectionHandle
                }
              }
            }
            ... on Craft_headerLinks_link_BlockType {
              uid
              type: typeHandle
              linkObject {
                text
                url
              }
              userLevel
            }
            ... on Craft_headerLinks_linksHeader_BlockType {
              uid
              type: typeHandle
              headingLink {
                url
                text
              }
              headerSublinks {
                ... on Craft_headerSublinks_BlockType {
                  headerLinkObject {
                    text
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const { headerLinks } = entry;

  const nav = headerLinks.map(col => {
    const { type } = col;

    if (type === "productsDropdown") {
      return {
        ...col,
        productLinks: col?.productLinks?.map(c => c.collectionHandle),
      };
    }

    if (type === "linksHeader") {
      return {
        ...col,
        header: col?.headingLink,
        children: col?.headerSublinks?.map(link => link?.headerLinkObject),
      };
    }

    return {
      ...col,
      level: parseInt(col?.userLevel, 10) - 1,
    };
  });

  return {
    nav,
  };
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
