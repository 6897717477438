import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AnnouncementQuery {
    craft {
      entry(section: "announcement") {
        ... on Craft_announcement_announcement_Entry {
          enabled: boolean0
          heading: heading0
          copy: descriptor0
          button: link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => entry;

const useDefaultsData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useDefaultsData;
