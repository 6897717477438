import React from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import { HtmlParser } from "@base";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag,
}) => {
  let Tag = tag;
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "font-sans text-4xl sm:text-6xl leading-tighter";
      break;
    case "h1-small":
      Tag = "h1";
      classes = "font-sans text-3xl sm:text-5xl leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-sans text-2xl sm:text-3xl leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-sans text-xl sm:text-2xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-sans text-1.5xl leading-tighter";
      break;
    case "2xl":
      classes = "block text-xl sm:text-1.5xl leading-normal font-sans";
      break;
    case "xl":
      classes = "block text-xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-sm sm:text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base xl:text-lg leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-sm leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-xs font-bold leading-none font-sans uppercase tracking-0.5";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={clsx(classes, _className, {
            quotes,
            "prose max-w-none": richText,
          })}
        >
          <HtmlParser html={child} />
        </Tag>
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={clsx(classes, _className, {
          quotes,
          "prose max-w-none": richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: false,
  quotes: false,
  children: null,
  dewidow: false,
};

export default Text;
