/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
// eslint-disable-next-line import/no-cycle
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputFile,
  InputSelect,
  HtmlParser,
} from "@base";
import Text from "./Text";

const Input = ({
  className: _className,
  condensed,
  dark,
  errorMessage,
  errorState,
  formState,
  group,
  hideLabel,
  id,
  label,
  name,
  options,
  placeholder,
  required,
  singleLine,
  type,
  value,
}) => {
  const [form, setForm] = formState || useState({});
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState(form[name] || []);

  useEffect(() => {
    setCheckedList(form[name] || []);
  }, []);

  let Field;
  switch (type) {
    case "file":
      Field = InputFile;
      break;
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset
        className={clsx(_className, {
          "text-pink": hasError,
        })}
      >
        {label && (
          <legend
            className={clsx("mb-2 inline-flex", {
              "sr-only": hideLabel,
              "text-white": dark,
              "text-black": !dark,
            })}
          >
            <span
              className={clsx(
                "block font-sans text-sm font-bold uppercase leading-none tracking-0.5"
              )}
            />
            <HtmlParser html={label} />
            {required && <span className="text-xxs">*</span>}
          </legend>
        )}
        <div
          className={clsx({
            "block columns-2 gap-3": group.length > 12,
          })}
        >
          {group?.map(_g => {
            return (
              <label
                htmlFor={_g?.id || name}
                key={value}
                className={clsx({
                  "mb-2 flex items-center": singleLine || _g?.singleLine,
                  "mb-2 inline-flex w-full cursor-pointer flex-row-reverse items-start justify-end text-xs leading-tight":
                    type === "checkbox" || type === "radio",
                })}
              >
                <span
                  className={clsx("font-heading block", {
                    "mb-2":
                      !singleLine && type !== "checkbox" && type !== "radio",
                    "ml-2": type === "checkbox" || type === "radio",
                    "mr-2": singleLine || _g?.singleLine,
                    "text-white": dark,
                    "text-black": !dark,
                  })}
                >
                  <HtmlParser html={_g?.label} />
                </span>
                <Field
                  {..._g}
                  id={_g?.id || name}
                  name={_g?.name || name}
                  value={_g.value}
                  formState={[form, setForm]}
                  hasError={hasError}
                  groupState={
                    type === "checkbox" ? [checkedList, setCheckedList] : null
                  }
                  condensed={condensed}
                  dark={dark}
                  className={clsx("font-medium", {
                    "cursor-pointer": type === "checkbox",
                  })}
                  checked={checkedList.includes(_g.value)}
                />
              </label>
            );
          })}
        </div>
        {errorMessage && hasError && (
          <Text variant="xs" className="text-pink mt-1">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={clsx(_className)}>
      <label
        htmlFor={id || name}
        className={clsx("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "text-pink": hasError,
        })}
      >
        <span
          className={clsx("font-heading flex gap-1", {
            "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
            "mr-2": singleLine,
            "ml-2": type === "checkbox" || type === "radio",
            "sr-only": hideLabel,
            "text-white": dark,
            "text-black": !dark,
          })}
        >
          <HtmlParser html={label} />
          {required && <span className="text-xxs">*</span>}
        </span>
        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          condensed={condensed}
          dark={dark}
          className={clsx("font-medium", {
            "border border-orange": !dark,
          })}
        />
      </label>
      {errorMessage && hasError && (
        <Text variant="xs" className="text-pink mt-1">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

Input.defaultProps = {
  dark: true,
};

export default Input;
