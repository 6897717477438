import React from "react";
import propTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { HtmlParser } from "@base";

const EmbedCode = ({ code: _code }) => {
  if (_code) {
    // standardize urls + strip duplicitous code
    const code = _code
      .replace(`<script async src="//www.instagram.com/embed.js"></script>`, "")
      .replace(
        `<script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`,
        ""
      )
      .replace("https://", "//")
      .replace("http://", "//")
      .replace("//", "https://");
    if (typeof window !== "undefined") {
      return (
        <div className="embedCode">
          <HtmlParser html={code} />
        </div>
      );
    }
  }
  return <div />;
};

EmbedCode.propTypes = {
  code: propTypes.string.isRequired,
};

export default EmbedCode;
