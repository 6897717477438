import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import { useAppState } from "@state";
import useGdprData from "@staticQueries/GdprQuery";
import { Script } from "gatsby";

import { Button, Container, Icon, Text } from "@atoms";
import gdprUtil from "@utils/gdpr";

const GdprBanner = () => {
  const gdprCookieName = process.env.GATSBY_APP_NAME;
  const { hasGdprConsent } = gdprUtil;

  const { enabled, heading, copy, button } = useGdprData();

  const hasWindow = typeof window !== "undefined";

  const [close, setClose] = useState(!enabled);
  const dataLayer = hasWindow ? window.dataLayer || [] : [];

  const [{ layout }, dispatch] = useAppState();
  const { gdpr } = layout;

  const setDoNotTrack = state => {
    if (hasWindow) {
      window.localStorage.setItem("doNotTrack", state);
      setClose(state);
    }
    return state;
  };

  useEffect(() => {
    if (hasWindow) {
      if (
        window.localStorage.getItem("doNotTrack") ||
        hasGdprConsent(gdprCookieName)
      ) {
        setClose(true);
      }
    }
  }, []);

  // hide the GDPR banner based on GDPR acceptance
  useEffect(() => {
    if (gdpr) {
      setClose(true);
      if (!dataLayer.find(v => v.gdprAccepted === true)) {
        dataLayer.push({
          event: "gdpr-accepted", // used to trigger other events in GTM, namely loading Hubspot and Google Analytics
          gdprAccepted: true, // used by other triggers to determine if they're allowed to run
          doNotTrack: window.localStorage.getItem("doNotTrack"), // used to filter out internal Cosmic traffic
        });
      }
    }
  }, [gdpr]);

  const variants = {
    visible: {
      y: "0%",
      opacity: 1,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      },
    },
    hidden: {
      y: "20%",
      opacity: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      },
    },
  };

  return (
    <>
      {close && (
        <>
          <Script
            id="gtm"
            src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`}
            strategy="idle"
            onLoad={() => {
              window.dataLayer = window.dataLayer || [];

              function gtag() {
                // eslint-disable-next-line prefer-rest-params
                window.dataLayer.push(arguments);
              }
              gtag("js", new Date());

              gtag("config", process.env.GATSBY_GTM_ID);

              window.dataLayer.push({ event: "routeChange" });
              window.dataLayer.push({ event: "gtmLoaded" });
            }}
          />
          {process.env.GATSBY_HUBSPOT_PORTAL_ID && (
            <Script
              id="hs-scripts"
              src={`//js.hs-scripts.com/${process.env.GATSBY_HUBSPOT_PORTAL_ID}.js`}
              strategy="idle"
            />
          )}
        </>
      )}
      <m.div
        initial={{ y: "20%", opacity: 0 }}
        animate={close ? "hidden" : "visible"}
        variants={variants}
        className={clsx(
          "fixed inset-x-0 bottom-0 z-[100] my-4 w-full md:my-8",
          { "pointer-events-none": gdpr || close },
          { "pointer-events-auto": !gdpr || !close }
        )}
      >
        <Container>
          <div className="bg-white shadow-xl">
            <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
              <div className="flex flex-grow flex-col items-baseline gap-3">
                {heading && (
                  <Text className="mr-4 font-bold leading-snug">{heading}</Text>
                )}
                <Text richText>{copy}</Text>
              </div>
              <div className="flex flex-shrink-0 items-center gap-3 md:w-auto md:justify-center">
                <Button
                  size="sm"
                  onClick={() => dispatch({ type: "acceptGdpr" })}
                >
                  {button}
                </Button>
                <button
                  className="pr-4 md:pr-0"
                  type="button"
                  onClick={() => setDoNotTrack(true)}
                >
                  <span className="sr-only">close</span>
                  <Icon
                    name="close"
                    className="h-6 w-6 text-white opacity-50 duration-300 hover:opacity-100"
                  />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </m.div>
    </>
  );
};

export default GdprBanner;
