/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import clsx from "clsx";

const InputText = ({
  name,
  placeholder,
  // required,
  formState,
  hasError,
  options,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={clsx(
        "w-full max-w-full rounded bg-white p-3 text-black",
        _className,
        {
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    >
      <>
        <option key="placeholder" value="" selected disabled>
          {placeholder || "Select One"}
        </option>
        {options?.map((option, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </>
    </select>
  );
};

InputText.defaultProps = {
  placeholder: "Select One",
};

export default InputText;
