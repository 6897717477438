const getPrice = (price, price_mod, id, prices) => {
  if (id && prices) {
    const dPrice = prices.find(v => v.id === id);
    if (dPrice) {
      return dPrice.price;
    }
  }
  const price_mod_int = parseInt(price_mod, 10);

  if (price_mod_int) {
    const priceInCents = price * (100 + price_mod_int);
    return (priceInCents / 100).toFixed(2);
  }
  return price.toFixed(2);
};

export default getPrice;
