import layout from "./reducers/layout";
import modal from "./reducers/modal";
import createAppState from "./lib/createAppState";
// shop stuff
import user from "./reducers/user";
import cart from "./reducers/cart";
import filters from "./reducers/filters";

const reducer = {
  layout: layout.reducer,
  modal: modal.reducer,
  user: user.reducer,
  cart: cart.reducer,
  filters: filters.reducer,
};

const initialState = {
  layout: layout.initialState,
  modal: modal.initialState,
  user: user.initialState,
  cart: cart.initialState,
  filters: filters.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
