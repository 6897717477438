import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllTongues {
    tongues: allTongue {
      nodes {
        id
        tongue
      }
    }
  }
`;

const dataResolver = ({ tongues: { nodes } }) => nodes;

const useDefaultsData = () => {
  const data = useStaticQuery(query);
  const resolved = useMemo(() => dataResolver(data), []);
  return resolved;
};

export default useDefaultsData;
