import React from "react";
import clsx from "clsx";

const InputTextarea = ({
  name,
  placeholder,
  // required,
  formState,
  hasError,
  id,
  // dark,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <textarea
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={clsx(
        "min-h-[6rem] w-full max-w-full rounded bg-white p-3 text-black",
        _className,
        {
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

InputTextarea.defaultProps = {};

export default InputTextarea;
