import React, { useState } from "react";
import clsx from "clsx";

const Fieldset = ({
  children,
  className: _className,
  condensed,
  dark,
  errorState,
  formState,
  hideLabel,
  label,
}) => {
  const [form, setForm] = formState || useState({});
  const [formError, setFormError] = errorState || useState([]);

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
        condensed,
        dark,
      });
    }
    return child;
  });

  return (
    <fieldset className={clsx(_className, {})}>
      {label && (
        <legend
          className={clsx("font-heading mb-2", {
            "sr-only": hideLabel,
            "text-teal": dark,
            "text-black": !dark,
          })}
        >
          {label}
        </legend>
      )}
      {childrenWithProps}
    </fieldset>
  );
};

Fieldset.defaultProps = {};

export default Fieldset;
