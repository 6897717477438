import React, { useMemo, useEffect } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import MiniSearch from "minisearch";
import debounce from "lodash.debounce";

const ArticleSearch = ({ content, queryState, resultsState }) => {
  const debounceFunc = useMemo(() => debounce((q, s) => s(q), 300), []);
  const [query, setQuery] = queryState;
  // eslint-disable-next-line no-unused-vars
  const [results, setResults] = resultsState;

  const miniSearch = new MiniSearch({
    fields: Object.keys(content[0] || []), // fields to index for full-text search
    // storeFields: ["title", "category"], // fields to return with search results
    searchOptions: {
      boost: { title: 2, name: 2 },
      fuzzy: 0.2,
      prefix: true,
    },
  });

  useEffect(() => {
    if (query && query.length) {
      const _results = miniSearch.search(query, { combineWith: "AND" });
      // eslint-disable-next-line no-console
      console.log(_results);
      setResults(_results.map(r => content.find(c => c.id === r.id)));
    } else {
      setResults(content);
    }
  }, [query]);

  miniSearch.addAll(content);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      debounceFunc.cancel();
    };
  }, []);

  return (
    <div>
      <div className="relative flex w-full items-center overflow-hidden rounded border border-orange bg-white p-5 text-sm transition duration-300">
        <input
          onChange={e => setQuery(e.target.value)}
          placeholder="Search"
          className={clsx(
            "absolute inset-0 h-full w-full p-3 pr-10 placeholder:text-black"
          )}
          onFocus={e => setQuery(e.target.value)}
        />
        <Icon
          name="search"
          className="pointer-events-none absolute bottom-0 right-3 top-3 z-10 h-4 w-4"
        />
      </div>
    </div>
  );
};

ArticleSearch.defaultProps = {};

export default ArticleSearch;
