import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const toggleBodyScroll = (enable, element) => {
  if (enable) {
    enableBodyScroll(element);
  } else {
    disableBodyScroll(element, { allowTouchMove: true });
  }
};

export default toggleBodyScroll;
