import React, { useState } from "react";
import { m, AnimatePresence } from "framer-motion";
import clsx from "clsx";

const Tip = ({ hover, content, anchor, className }) => {
  return (
    <AnimatePresence>
      {hover && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={clsx(
            "absolute inset-x-0 mb-0.5 flex flex-col items-center fill-gray-dark text-gray-dark",
            {
              "bottom-full ": anchor === "top",
              "top-full ": anchor === "bottom",
            },
            className
          )}
        >
          {anchor === "bottom" && (
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="rotate-180"
            >
              <path
                d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z"
                fill="currentColor"
              />
            </svg>
          )}
          <div className="-mb-0.5 w-max max-w-[12rem] rounded bg-gray-dark p-3 font-sans text-xs leading-relaxed text-white">
            {content}
          </div>
          {anchor === "top" && (
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z"
                fill="currentColor"
              />
            </svg>
          )}
        </m.div>
      )}
    </AnimatePresence>
  );
};

const Tooltip = ({ children, content, anchor, className: _className }) => {
  const [hover, setHover] = useState(false);

  return (
    <m.div
      className="relative flex"
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      {children}
      <Tip
        content={content}
        hover={hover}
        anchor={anchor}
        className={_className}
      />
    </m.div>
  );
};

Tooltip.defaultProps = {
  anchor: "top",
};

export default Tooltip;
