/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable global-require */

// import styles index
import "./src/css/index.css";
import { AnimatePresence, MotionConfig } from "framer-motion";

import React from "react";
import { AppStateProvider } from "@state";

import Default from "@templates/Default";

export const wrapPageElement = ({ element, props }) => {
  const { pageContext, location } = props;
  return (
    <Default pageContext={pageContext} location={location}>
      <MotionConfig transition={{ duration: 0.3, type: "tween" }}>
        <AnimatePresence mode="wait" initial={false}>
          {element}
        </AnimatePresence>
      </MotionConfig>
    </Default>
  );
};

export const wrapRootElement = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>;
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};
