import React from "react";
// eslint-disable-next-line import/no-cycle
import { AppLink } from "@base";
import clsx from "clsx";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  outline,
  disabled,
}) => {
  const defaultClassName = `rounded inline-flex justify-center items-center cursor-pointer text-center relative z-0 font-bold group leading-tighter transition duration-300 border border-current`;

  const sizes = {
    lg: "py-3 px-8 text-sm",
    md: "py-2 px-5 text-sm",
    sm: "py-2 px-6 text-xs",
    xs: "py-2 px-5 text-xxs",
    "2xs": "py-1.5 px-2 text-xxs",
    "3xs": "py-0.5 px-2 text-xxs",
  };

  const colors = {
    black: "text-black",
    orange: "text-orange hover:text-orange-dark",
    red: "text-red hover:text-red-dark",
    white: "text-white",
    green: "text-green hover:text-green-dark",
  };

  const classes = clsx(
    defaultClassName,
    sizes[size] || "py-4 px-10 text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
      "border-2": size === "lg",
      "opacity-50 pointer-events-none": disabled,
    }
  );

  const textClasses = clsx("relative z-10 flex", {
    "text-current group-hover:text-white": outline && color !== "white",
    "text-black": !outline && color === "white",
    "text-white": !outline && color !== "white",
    "group-hover:text-black": color === "white",
  });

  if (to && to.length > 1) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={clsx(classes, {
          "bg-current": !outline,
          "hover:bg-current": outline,
          "opacity-50": disabled,
        })}
        onClick={onClick}
      >
        <span className={textClasses}>{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(classes, {
        "bg-current": !outline,
        "hover:bg-current": outline,
        "opacity-50": disabled,
      })}
      onClick={onClick}
    >
      <span className={textClasses}>{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "orange",
  title: null,
  type: "button",
  onClick: null,
  size: "md",
  to: null,
};

export default Button;
