import React, { useState } from "react";
import clsx from "clsx";
import usePagination from "@hooks/usePagination";
import chunkify from "@utils/chunkify";

import { Button, Icon } from "@atoms";

const Pagination = ({
  data,
  pageSize,
  rangeLimit,
  showNavigation,
  component: Component,
  // className: _className,
}) => {
  const pages = Math.ceil((data?.length || 0) / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  if (pages) {
    const paginationRange = usePagination({
      pages,
      currentPage,
      rangeLimit,
    });

    const nextPage = () => {
      setCurrentPage(page => (page < pages ? page + 1 : page));
    };
    const prevPage = () => {
      setCurrentPage(page => (page > 1 ? page - 1 : 1));
    };
    // use the number within the button to set the page
    const changePage = event => {
      const pageNumber = Number(event.target.textContent);
      setCurrentPage(pageNumber);
    };
    // chunk the data into pages
    const paginatedData = chunkify([...data], pages, false);

    return (
      <>
        <ul className="space-y-6">
          {paginatedData[currentPage - 1].map((item, i) => (
            <li key={item.uid}>
              <Component order={i + 1} {...item} />
            </li>
          ))}
        </ul>
        {pages > 1 && (
          <div className="mt-10 flex flex-wrap gap-4 sm:mt-16 md:mt-24">
            {/* previous button */}
            {showNavigation && (
              <Button onClick={prevPage} size="2xs">
                <Icon
                  name="chevron"
                  className={clsx("h-4 w-4 rotate-180", {})}
                />
              </Button>
            )}
            {/* page number buttons */}
            {paginationRange.map(pageNumber => {
              if (pageNumber === "...") {
                return (
                  <span
                    key={pageNumber}
                    className="leading-tighter p-2 text-black"
                  >
                    {pageNumber}
                  </span>
                );
              }
              return (
                // using vanilla button here to allow for active button styling
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={pageNumber}
                  type="button"
                  className={clsx(
                    "leading-tighter rounded border border-orange px-3 py-0.5 text-xs text-black text-orange duration-300 hover:bg-orange hover:text-white focus:ring-4 focus:ring-orange",
                    {
                      "bg-orange text-white": currentPage === pageNumber,
                    }
                  )}
                  onClick={changePage}
                >
                  <span>{pageNumber}</span>
                </button>
              );
            })}
            {/* next button */}
            {showNavigation && (
              <Button onClick={nextPage} size="2xs">
                <Icon name="chevron" className="h-4 w-4" />
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
  return null;
};

Pagination.defaultProps = {
  pageSize: 9,
  rangeLimit: 1,
  showNavigation: true,
};

export default Pagination;
