import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query allCollections {
    collections: allShopifyCollection {
      nodes {
        id
        handle
        description
        storefrontId
        title
        url: gatsbyPath(filePath: "/collections/{ShopifyCollection.handle}")
        image {
          url: src
          height
          width
        }
        seo {
          description
          title
        }
        products {
          handle
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

const dataResolver = ({ collections }) => {
  const all = collections.nodes
    .map(c => ({
      ...c,
      ...c.metafields.reduce(
        (agg, meta) => ({ ...agg, [meta.key]: meta.value }),
        {}
      ),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return all
    .map(c => ({
      ...c,
      children: all.filter(col => col?.parent_collection === c.storefrontId),
    }))
    .filter(
      c =>
        !c.parent_collection &&
        ![
          "books",
          "chart",
          "dispensing-supplies",
          "liniments-medicated-oils-and-plasters",
          "miscellaneous-chinese-patents",
          "spring-wind-herbs",
          "the-three-treasures",
        ].includes(c.handle)
    );
};

const useDefaultsData = () => {
  const data = useStaticQuery(query);
  const resolved = useMemo(() => dataResolver(data), []);
  return resolved;
};

export default useDefaultsData;
