import { setCookie } from "@utils/cookies";

const initialState = {
  level: 0,
  type: null,
  token: null,
  info: null,
  cart: null,
  favorites: [],
  customers: null,
  customer: null,
  price_mod: 0,
};

const reducer = (state, action) => {
  const { type, token, userType, info, favorites, customer, customers } =
    action;

  switch (type) {
    case "setToken":
      setCookie("usertoken", token);
      return { ...state, token };
    case "setCustomers":
      return {
        ...state,
        customers: (customers || []).map(c => ({
          ...c,
          id:
            typeof c.id === "string"
              ? parseInt(c.id.split("/").pop(), 10)
              : c.id,
        })),
      };
    case "setUser":
      return {
        ...state,
        type: userType,
        level:
          {
            consumer: 1, // consumers are > 0 || === 1
            distributor: 2, // distributors are > 1 || === 2
            practitioner: 3, // practitioners are > 2 || === 3
            admin: 4, // admins are > 3 || === 4
          }[userType] || 0,
        info: {
          ...info,
          id:
            typeof info.id === "string"
              ? parseInt(info.id.split("/").pop(), 10)
              : info.id,
        },
        prices: info.prices,
        price_mod: info.price_mod || 0,
        // handle every possible outcome here
        favorites: info.favorites
          ? JSON.parse(info.favorites || "[]")
          : [] || [],
      };
    case "setCustomer":
      return {
        ...state,
        customer: {
          ...customer,
          id:
            typeof customer?.id === "string"
              ? parseInt(customer?.id.split("/").pop(), 10)
              : customer?.id,
        },
        price_mod: customer?.price_mod || 0,
      };
    case "setFavorites":
      return {
        ...state,
        favorites,
      };
    case "logout":
      setCookie("usertoken", "");
      return initialState;
    default:
      return state;
  }
};

export default {
  reducer,
  initialState,
};
