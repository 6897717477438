import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllOrgans {
    organs: allOrgan {
      nodes {
        id
        organ
      }
    }
  }
`;

const dataResolver = ({ organs: { nodes } }) => nodes;

const useDefaultsData = () => {
  const data = useStaticQuery(query);
  const resolved = useMemo(() => dataResolver(data), []);
  return resolved;
};

export default useDefaultsData;
