import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import { m, AnimatePresence } from "framer-motion";

const EditField = ({
  label,
  name: key,
  value: initial,
  onSave,
  type,
  max,
  append,
  prepend,
}) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(initial);

  const onSubmit = (e, v) => {
    e.preventDefault();
    if (onSave) {
      onSave(v);
    }
    setActive(false);
  };

  useEffect(() => {
    if (active && key) document.getElementById(key).focus();
  }, [active]);

  return (
    <div className="relative flex gap-2">
      <span>
        <b>{label}</b> {prepend}
        {value}
        {append}
      </span>
      <button onClick={() => setActive(a => !a)} type="button">
        <span className="sr-only">edit</span>
        <Icon
          name="edit"
          className={clsx("h-4 w-4 rounded border border-orange", {
            "bg-orange text-white": active,
            "text-orange": !active,
          })}
        />
      </button>
      <AnimatePresence>
        {active && (
          <m.form
            className="absolute top-[100%] z-10 -ml-1 mt-1 flex gap-1 rounded bg-white p-1 shadow-xl"
            onSubmit={e => onSubmit(e, value)}
          >
            <input
              required
              type={type || "text"}
              // type="number"
              className="rounded border border-orange px-3 py-1 text-sm"
              name={key}
              id={key}
              onChange={e => setValue(e.target.value)}
              value={value}
              max={max}
              // max="300"
            />
            <button type="submit">
              <span className="sr-only">submit</span>
              <Icon
                name="check"
                className="h-8 w-8 rounded bg-orange stroke-2 p-1 pl-1.5 text-white"
              />
            </button>
          </m.form>
        )}
      </AnimatePresence>
    </div>
  );
};

export default EditField;
