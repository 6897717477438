import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { AppLink } from "@base";
import { Image, Icon, Button, ButtonAlt } from "@atoms";
import { useAppState } from "@state";
import FavoriteToggle from "./FavoriteToggle";

const CardProduct = ({
  title,
  url,
  image,
  productLine,
  chineseName,
  variants,
  typeFilter,
  storefrontId,
  inverse,
  // inStock,
  rx,
  tags,
  showCompareButton,
  compare,
}) => {
  const [{ user, cart }, dispatch] = useAppState();
  const filteredVariants = variants.filter(v => {
    const recsArray = Array.isArray(user?.info?.recommendations)
      ? user?.info?.recommendations
      : [];
    const recs =
      user.level === 1
        ? recsArray?.map(rec => rec?.id).includes(v.storefrontId)
        : true;
    const filters = typeFilter
      ?.map(t => t.replace(/s$/, ""))
      .includes(v?.type?.toLowerCase().replace(/s$/, ""));

    return !typeFilter?.length ? recs : recs && filters;
  });
  const firstAvailableVariant = useMemo(
    () =>
      filteredVariants.find(o => o.inStock) ||
      filteredVariants[0] ||
      variants[0],
    []
  );
  const [variant, setVariant] = useState(firstAvailableVariant);

  const [qty, setQty] = useState(1);

  useEffect(() => {
    const filteredTypes = filteredVariants.filter(v => {
      const types = typeFilter?.map(t => t.replace(/s$/, ""));
      return (
        !typeFilter?.length ||
        types.includes(v?.type?.toLowerCase().replace(/s$/, ""))
      );
    });
    setVariant(filteredTypes.find(p => p.inStock) || filteredTypes[0]);
  }, [typeFilter]);

  const displayImage = variant?.image || image || { url: "/png/icon.png" };

  const hasVariants = !!filteredVariants.length;

  return (
    <div className="relative flex h-full flex-col items-start gap-3">
      {user.level > 1 && <FavoriteToggle storefrontId={storefrontId} />}
      {tags?.includes("Back in Stock") && (
        <Icon
          name="inStockBadge"
          className="pointer-events-none absolute -right-4 -top-4 z-10 h-20 w-20"
        />
      )}
      <AppLink
        to={url}
        className={clsx(
          "relative flex aspect-square w-full rounded border border-orange bg-white",
          { "p-3": image, "p-[20%]": !image }
        )}
      >
        <div className="relative flex-grow">
          <Image
            key={variant?.image?.url || displayImage?.url}
            image={displayImage}
            fill
            objectFit="contain"
          />
        </div>
      </AppLink>
      <AppLink to={url}>
        <h3 className="font-serif text-xl">{title}</h3>
      </AppLink>
      {chineseName && (
        <p
          className={clsx("-mt-1 text-sm", {
            "text-green": !inverse,
          })}
        >
          {chineseName}
        </p>
      )}
      <p
        className={clsx("text-xs uppercase", {
          "font-bold text-orange": !inverse,
        })}
      >
        {productLine?.title || "Kan Herbs"}
      </p>
      {/* is at least distributor or it's recommended */}
      {(user.level > 1 || (user.type && hasVariants)) && (
        <>
          <div className="flex gap-2">
            <div className="flex items-center gap-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="amount"
                className="sr-only text-xs font-bold uppercase text-green"
              >
                {cart.mode === "order" ? "Qty" : "Refills"}:
              </label>
              <select
                id="amount"
                onChange={e => setQty(e.target.value)}
                name="amount"
                className={clsx(
                  "border-b-2 border-orange bg-transparent py-1 text-xs font-bold",
                  { "border-white": inverse, "border-orange": !inverse }
                )}
              >
                <option value="" disabled>
                  {cart.mode === "order" ? "Qty" : "Refills"}:
                </option>
                {/* eslint-disable-next-line prefer-spread */}
                {Array.apply(null, { length: 99 })
                  .map(Number.call, Number)
                  .map(n => (
                    <option key={n} selected={n === 0} value={n + 1}>
                      {n + 1}
                    </option>
                  ))}
              </select>
            </div>
            <div className="flex items-center gap-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="variant"
                className="sr-only text-xs font-bold uppercase text-green"
              >
                Size:
              </label>
              <select
                name="variant"
                onChange={e =>
                  setVariant(
                    variants.find(v => v.storefrontId === e.target.value)
                  )
                }
                className={clsx(
                  "w-full border-b-2 border-orange bg-transparent py-1 text-xs font-bold",
                  { "border-white": inverse, "border-orange": !inverse }
                )}
              >
                <option value="" disabled>
                  Size & Type
                </option>
                {(filteredVariants || variants).map(option => (
                  <option
                    key={option.id}
                    selected={
                      firstAvailableVariant.storefrontId === option.storefrontId
                    }
                    // disabled={!option.inStock}
                    value={option.storefrontId}
                  >
                    {option.selectedOptions.reduce(
                      (agg, v) => `${agg} ${v.value}`,
                      ""
                    )}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-1 text-lg font-bold">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(variant.retail)}
          </div>
          <div className="mt-1 flex flex-col items-start gap-2">
            {user?.type && (
              <Button
                outline
                size="md"
                color={inverse ? "white" : "black"}
                disabled={!variant?.inStock}
                onClick={() =>
                  dispatch({
                    type: "addToCart",
                    variant: variant.storefrontId,
                    qty,
                  })
                }
              >
                {variant?.inStock ? "Add to Order" : "Out of Stock"}
              </Button>
            )}

            {/* user type is practitioner */}
            {user?.type > 2 && user?.customer && (
              <ButtonAlt
                onClick={() =>
                  dispatch({
                    type: "addToCart",
                    variant: variant.storefrontId,
                    qty,
                  })
                }
              >
                Recommend to Client
              </ButtonAlt>
            )}
          </div>
          {rx && <div className="text-sm italic text-gray-dark">{rx}</div>}
        </>
      )}
      {showCompareButton && !compare && (
        <Button
          to={`/compare?id=${storefrontId?.split("/").pop()}`}
          outline
          size="md"
          color={inverse ? "white" : "black"}
        >
          Compare
        </Button>
      )}
      {showCompareButton && compare && (
        <Button
          to={`/compare?id=${compare}&cid=${storefrontId?.split("/").pop()}`}
          outline
          size="md"
          color={inverse ? "white" : "black"}
        >
          Compare
        </Button>
      )}
    </div>
  );
};

export default CardProduct;
