import React, { useState } from "react";
import clsx from "clsx";
import { Input } from "@atoms";
import FormsData from "@staticQueries/FormsQuery";
import { getCookie } from "@utils/cookies";
import FieldSet from "@atoms/FieldSet";
import navigate from "@utils/navigateWithParams";
import { HtmlParser } from "@base";
import FormContainer from "./FormContainer";

const HubspotForm = ({
  condensed,
  dark,
  formId,
  onSuccess,
  requiredOnly,
  successMessage,
  preSubmit,
}) => {
  const { forms } = FormsData();
  const { formFields, redirect } = forms[formId];
  const [form, setForm] = useState({});

  const submitForm = async () => {
    if (preSubmit) {
      const pre = await preSubmit();
      if (!pre) {
        return { error: "terms rejected" };
      }
    }

    const fields = Object.entries(form).map(([name, value]) => ({
      name,
      // checkbox group arrays need to be transformed into a
      // semi-colon-separated string.
      value: Array.isArray(value) ? value.join(";") : value,
    }));

    const fileNames = formFields
      .reduce((agg, f) => [...agg, ...f.fields], [])
      .filter(field => field.type === "file")
      .map(field => field.name);

    try {
      // Make request to submit form with updated formData
      if (fileNames.length > 0) {
        try {
          await Promise.all(
            fileNames.map(name => {
              const fileForm = new FormData();
              fileForm.append("file", fields.find(f => f.name === name).value);

              return fetch("/api/uploadHubspotFile", {
                method: "POST",
                body: fileForm,
              })
                .then(res => res.json())
                .then(response => {
                  // Update formData with upload url
                  const fieldIdx = fields.findIndex(f => f.name === name);
                  if (fieldIdx !== -1) {
                    fields[fieldIdx].value = response?.objects?.[0]?.url;
                  }
                });
            })
          );
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          return { error };
        }
      }

      const data = await fetch("/api/submitHubspotForm", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          hubspotutk: getCookie("hubspotutk"),
          formId,
          pageUri: window.location.href,
          pageName: document.title,
          fields,
        }),
      });

      if (onSuccess) {
        onSuccess(Object.fromEntries(fields.map(f => [f.name, f.value])));
      }

      // redirect if there is one
      if (redirect?.length) {
        setTimeout(() => {
          navigate(redirect);
        }, 1000);
      }

      return data.json();
    } catch (error) {
      return error;
    }
  };

  return (
    <div className={clsx("flex w-full flex-col justify-center")}>
      <FormContainer
        className={clsx("relative mx-auto flex flex-col justify-center", {
          "text-white": dark,
        })}
        formState={[form, setForm]}
        onSubmit={submitForm}
        onSuccess={onSuccess}
        successMessage={successMessage}
        condensed={condensed}
        dark={dark}
      >
        {formFields?.map((field, i) => {
          if (field.richText.content.length) {
            return (
              <div
                key={field.name || i}
                className="font-heading mb-4 mt-8 text-sm leading-normal"
              >
                <HtmlParser html={field.richText.content} />
              </div>
            );
          }
          return (
            <FieldSet
              key={`hubspot-field--${i + 1}`}
              className="mb-4 flex w-full flex-wrap gap-4 md:flex-nowrap"
            >
              {field?.fields?.map((f, _i) => {
                const optionsIds = f?.options?.map(o => {
                  return { ...o, id: `${f?.name}${o?.value}` };
                });
                if (requiredOnly) {
                  if (f.required) {
                    return (
                      <Input
                        key={`${f?.name}--${_i + 1}`}
                        {...f}
                        className={clsx("w-full", {
                          "md:w-auto md:max-w-[60%]":
                            f.type === "checkbox" && field?.fields.length > 1,
                        })}
                        group={optionsIds}
                        required={f?.required}
                        errorMessage={f?.required && "This field is required"}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <Input
                    key={`${f?.name}--${_i + 1}`}
                    {...f}
                    className={clsx("w-full", {
                      "md:w-auto md:max-w-[60%]":
                        f.type === "checkbox" && field?.fields.length > 1,
                    })}
                    group={optionsIds}
                    required={f?.required}
                    errorMessage={f?.required && "This field is required"}
                  />
                );
              })}
            </FieldSet>
          );
        })}
        {/* this will come from the form if it should be here */}
        {/* {newsletterCheckbox && (
          <Input
            label="Sign me up for your newsletter"
            name="newsletterRadio"
            type="checkbox"
            value="true"
            className="relative bottom-0 mb-3 w-full md:absolute"
          />
        )} */}
      </FormContainer>
    </div>
  );
};

HubspotForm.defaultProps = {
  color: "teal",
  dark: true,
  condensed: false,
  requiredOnly: false,
};

export default HubspotForm;
