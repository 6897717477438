import React from "react";
import { useAppState } from "@state";
import { m, AnimatePresence } from "framer-motion";
import { Text, Icon, Container } from "@atoms";

const Status = () => {
  const [{ user }, dispatch] = useAppState();
  const variants = {
    show: {
      height: "auto",
      pointerEvents: "auto",
    },
    hide: {
      height: 0,
      pointerEvents: "none",
    },
  };

  return (
    <AnimatePresence initial>
      {user.customer && user?.customer?.id !== user?.info?.id && (
        <m.div
          variants={variants}
          initial="hide"
          animate="show"
          className="relative overflow-hidden bg-orange pr-4 text-white"
          key={user?.customer?.id}
        >
          <Container padding className="relative">
            <div className="flex flex-col gap-1 py-4 sm:flex-row sm:items-center sm:justify-between sm:gap-3">
              <div>
                <Text className="text-[16px] font-medium leading-tight">
                  Currently browsing for {user.customer.firstName}{" "}
                  {user.customer.lastName}.
                </Text>
              </div>
            </div>
            <button
              type="button"
              className="absolute bottom-0 right-1 top-0 flex items-center justify-center"
              onClick={() => {
                dispatch({
                  type: "setCustomer",
                  customer: user.info,
                });
              }}
            >
              <span className="sr-only">close</span>
              <Icon
                name="close"
                className="h-4 w-4 text-white opacity-50 transition duration-300 hover:opacity-100"
              />
            </button>
          </Container>
        </m.div>
      )}
    </AnimatePresence>
  );
};

export default Status;
