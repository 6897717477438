const getFormat = () => {
  if (typeof window !== "undefined") {
    const userAgent = (() => {
      const ua = navigator.userAgent;
      let tem;
      let M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return `IE ${tem[1] || ""}`;
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      // eslint-disable-next-line no-cond-assign
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(" ");
    })();

    const browser = userAgent.match(/[a-zA-Z ]*/)[0].replace(/\s/g, "");
    const version = parseInt(userAgent.match(/[0-9]+/g)[0], 10) || 999;

    // safari tests
    if (browser === "Safari") {
      if (version < 16 && version > 14) {
        return "webp";
      }
      if (version <= 14) {
        return null;
      }
    }
  }
  return "avif";
};

export const buildUrl = (url, params) => {
  if (url) {
    return `${url
      .replace(process.env.GATSBY_ASSET_BASE_URL, "/images/content/")
      .replace(
        process.env.GATSBY_SHOPIFY_CDN,
        "/images/store/"
      )}?${Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;
  }
  return "";
};

export const defaultParams = (eager, ar, pr = 1, source = "shopify") => {
  const fm = getFormat();
  const params = {
    auto:
      (eager || fm !== "avif" ? "compress" : "compress,format") +
      (source === "shopify" ? ",enhance" : ""),
    fit: eager || source === "shopify" ? "max" : "crop",

    q: pr > 1.67 && source !== "shopify" ? 60 : 90,
    crop: "faces",
    w: 360, // from state
    h: parseInt(ar ? 360 : 360 * ar, 10), //  from state
    dpr: pr > 2 ? 2 : pr,
    usm: source === "shopify" ? 10 : 0,
    usmrad: source === "shopify" ? 10 : 0,
  };
  if (fm !== "avif" && fm) {
    params.fm = fm;
  }
  return params;
};

export const getAltTag = async (imageUrl, alt) => {
  if (alt) {
    return false;
  }

  try {
    const thing = await fetch(`/api/cloudsight`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageUrl }),
    });
    const response = await thing.json();
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
};
