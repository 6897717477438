import React from "react";
import clsx from "clsx";

import * as svgs from "@svg";

const Icon = ({ name, className: _className = "w-16 h-16", fitHeight }) => {
  const Svg = svgs[name];

  return (
    <span
      className={clsx("block fill-current", _className, {
        "svg-icon--fit-height": fitHeight,
        "svg-icon": !fitHeight,
      })}
    >
      {Svg && <Svg />}
    </span>
  );
};

export default Icon;
