import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AllElements {
    elements: allElement {
      nodes {
        id
        element
      }
    }
  }
`;

const dataResolver = ({ elements: { nodes } }) => nodes;

const useDefaultsData = () => {
  const data = useStaticQuery(query);
  const resolved = useMemo(() => dataResolver(data), []);
  return resolved;
};

export default useDefaultsData;
