import React, { useEffect, useMemo, useState, useRef } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import useAllProductsData from "@shopify/staticQueries/AllProductsQuery";
import MiniSearch from "minisearch";
import { AnimatePresence, m } from "framer-motion";
import { AppLink } from "@base";

const SearchProduct = ({ onClick }) => {
  const products = useAllProductsData().sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const miniSearch = useMemo(() => {
    const ms = new MiniSearch({
      fields: ["title", "common", "chineseName"], // fields to index for full-text search
      storeFields: [
        "title",
        "url",
        "type",
        "common",
        "chineseName",
        "collections",
        "image",
        "variants",
        "metafields",
        "seo",
      ], // fields to return with search results
      searchOptions: {
        boost: { title: 2 },
        fuzzy: 0.1,
        prefix: true,
      },
    });
    ms.addAll(products);
    return ms;
  }, []);

  const search = q => {
    if (q?.length) {
      const searchResults = miniSearch.search(q);
      setResults(searchResults);
      setOpenSearch(true);
    }
  };

  useEffect(() => {
    if (query.length) {
      search(query);
    } else {
      setResults([]);
    }
  }, [query]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setOpenSearch(false);
      }
    }
    // Bind the event listener
    if (openSearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSearch]);

  return (
    <div className="relative w-full">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        <span className="mb-2 block font-bold">Search Item to Compare</span>
        <div className="relative w-full">
          <div className="relative flex w-full items-center overflow-hidden rounded border border-orange bg-white p-5 text-sm transition duration-300">
            <input
              id="compareSearch"
              name="compareSearch"
              onChange={e => setQuery(e.target.value)}
              // placeholder=""
              className={clsx(
                "absolute inset-0 h-full w-full p-3 pr-10 placeholder:text-black"
              )}
              onFocus={() => setOpenSearch(true)}
              ref={inputRef}
              type="text"
            />
            <Icon
              name="search"
              className="pointer-events-none absolute bottom-0 right-3 top-3 z-10 h-4 w-4"
            />
          </div>
          <AnimatePresence initial>
            {openSearch && (
              <m.ul
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute left-0 right-0 top-[calc(100%-1px)] z-20 max-h-60 overflow-auto rounded border border-orange bg-white p-3 py-1 transition-[height] duration-300"
                ref={searchRef}
              >
                {products
                  ?.filter(
                    o =>
                      !results.length || results.map(r => r.id).includes(o.id)
                  )
                  .map(r => {
                    return (
                      <li
                        key={r.id}
                        className="my-3 text-sm text-black hover:text-orange focus:text-orange"
                      >
                        <AppLink
                          className="text-left"
                          onClick={e => {
                            setOpenSearch(false);
                            return onClick({ e, r });
                          }}
                        >
                          <span className="flex flex-col gap-1">
                            <div className="flex gap-1">
                              <span>{r.common || r.title}</span>
                            </div>
                            {r.chineseName && (
                              <span className="text-xs text-green">
                                ({r.chineseName})
                              </span>
                            )}
                          </span>
                        </AppLink>
                      </li>
                    );
                  })}
              </m.ul>
            )}
          </AnimatePresence>
        </div>
      </label>
    </div>
  );
};

export default SearchProduct;
