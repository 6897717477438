import React, { useRef, useState, useEffect } from "react";
// import getTailwindColor from "@utils/getTailwindColor";
// eslint-disable-next-line import/no-cycle
import { Button, Icon } from "@atoms";

/**
 *
 * `FileSelector` A styled file selector control
 *
 */

const FileSelector = ({
  onChange,
  value,
  name,
  // label,
  // required,
  // backgroundOverride,
  className,
  formState,
}) => {
  const [filename, setFilename] = useState("");
  const input = useRef();
  const [form, setForm] = formState;

  const handleChange = () => {
    const f = input.current.value.split(/(\\|\/)/g).pop();
    setFilename(f.length > 18 ? `${f.substring(0, 18)}...` : f);
    setForm({ ...form, [name]: input.current.files[0] });

    // Form handler expects value to be passed as `event.target.value`;
    if (onChange) {
      onChange({ target: { value: input.current.files[0] || "" } });
    }
    // input.current.blur();
    document.activeElement.blur();
  };

  const clearFile = () => {
    input.current.value = "";
    handleChange("");
  };

  const handleClick = () => {
    if (filename) {
      clearFile();
    } else {
      input.current.click();
    }
  };

  useEffect(() => {
    if (value === "") {
      clearFile();
    }
  }, [value]);

  // const red = getTailwindColor("red.DEFAULT");
  // const white = getTailwindColor("white.DEFAULT");

  return (
    <div className={`FileSelector relative ${className}`}>
      <Button size="lg" fullWidth onClick={handleClick} color="white">
        {!filename && <Icon name="upload" className="mr-2 h-4 w-4 stroke-2" />}
        {filename && <Icon name="file" className="mr-2 h-4 w-4 stroke-2" />}
        {filename || "Upload File"}
      </Button>

      <input
        ref={input}
        type="file"
        name={name}
        id={name}
        className="absolute inset-0 hidden"
        onChange={handleChange}
      />
    </div>
  );
};

FileSelector.defaultProps = {
  required: true,
  backgroundOverride: null,
  className: "",
};

export default FileSelector;

// import React from "react";
// import clsx from "clsx";

// const InputText = ({
//   name,
//   placeholder,
//   required,
//   formState,
//   hasError,
//   id,
//   className: _className,
// }) => {
//   const [form, setForm] = formState;

//   return (
//     <input
//       type="text"
//       name={name}
//       id={id || name}
//       placeholder={placeholder}
//       className={clsx(
//         "w-full max-w-full rounded bg-white p-3 text-black",
//         _className,
//         {
//           "!border-red": hasError,
//         }
//       )}
//       onChange={e => {
//         setForm({ ...form, [name]: e.target.value });
//       }}
//     />
//   );
// };

// InputText.defaultProps = {};

// export default InputText;
