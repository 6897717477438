import React, { useEffect, useMemo, useState, useRef } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import { AnimatePresence, m } from "framer-motion";
import { AppLink, HtmlParser } from "@base";
import debounce from "lodash.debounce";
import algoliasearch from "algoliasearch";
import { useAppState } from "@state";
import { startCase } from "lodash";
import getParam from "@utils/getParam";

const siteClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SITE_SEARCH_KEY
);

const StoreSearch = ({ id }) => {
  const [, dispatch] = useAppState();
  // search state
  const [loaded, setLoaded] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  // refs
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const debounceFunc = useMemo(() => debounce((q, s) => s(q), 1250), []);

  const defaultParams = {
    attributesToRetrieve: ["*"],
    attributesToSnippet: "*:20",
    snippetEllipsisText: "…",
    responseFields: "*",
    explain: "*",
    maxValuesPerFacet: 100,
    highlightPreTag: "<strong><em>",
    highlightPostTag: "</em></strong>",
    typoTolerance: false,
  };

  const search = async q => {
    if (q?.length) {
      try {
        const queries = [
          {
            indexName: "products",
            query: q,
            params: {
              attributesToHighlight: ["pinyin", "title"],
              ...defaultParams,
            },
          },
          {
            indexName: "collections",
            query: q,
            params: {
              attributesToHighlight: ["title"],
              ...defaultParams,
            },
          },
          {
            indexName: "herbs",
            query: q,
            params: {
              attributesToHighlight: ["title", "common"],
              ...defaultParams,
            },
          },
          {
            indexName: "indications",
            query: q,
            params: {
              attributesToHighlight: ["title"],
              ...defaultParams,
            },
          },
          {
            indexName: "patterns",
            query: q,
            params: {
              attributesToHighlight: ["title"],
              ...defaultParams,
            },
          },
          {
            indexName: "actions",
            query: q,
            params: {
              attributesToHighlight: ["title"],
              ...defaultParams,
            },
          },
        ];
        const _search = await siteClient.multipleQueries(queries);

        const formattedResults = _search?.results
          ?.map(result => {
            if (result.hits.length === 0) return null;
            return {
              type: result?.index?.replace(/s+$/, ""),
              results: result.hits.map(hit => {
                return {
                  ...hit,
                  uid: hit.objectId || hit.id,
                };
              }),
            };
          })
          .filter(r => r)
          .sort(a => (a?.type === "indication" ? 1 : -1));
        setResults(formattedResults);
        setOpenSearch(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const sQuery = getParam("q");
    if (sQuery) {
      inputRef.current.value = sQuery;
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (query?.length && loaded) {
      setLoading(true);
      debounceFunc(query, search);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      urlParams.set("q", query);
      window.history.replaceState(null, null, `?${urlParams}`);
    } else {
      setLoading(false);
      setResults([]);
    }
  }, [query]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setOpenSearch(false);
      }
    }
    // Bind the event listener
    if (openSearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSearch]);

  return (
    <div className="relative w-full">
      <div
        className={clsx(
          "relative flex w-full items-center overflow-hidden rounded-t border border-orange bg-white p-5 text-sm transition duration-300",
          { "rounded-b": !(openSearch && !!results.length) }
        )}
      >
        <input
          id={id}
          onChange={e => setQuery(e.target.value)}
          placeholder="Enter a query to search our products."
          className={clsx(
            "absolute inset-0 h-full w-full p-3 pr-10 placeholder:text-gray-dark/60"
          )}
          onFocus={e => {
            if (e.target.value) search(e.target.value);
          }}
          onBlur={() => setLoading(false)}
          ref={inputRef}
        />
        {!loading && (
          <Icon
            name="search"
            className="pointer-events-none absolute bottom-0 right-3 top-3 z-10 h-4 w-4"
          />
        )}
        {loading && (
          <Icon
            name="loading"
            className="pointer-events-none absolute bottom-0 right-3 top-1.5 h-6 w-6 animate-spin text-orange"
          />
        )}
      </div>
      <AnimatePresence initial>
        {!!results.length && openSearch && (
          <m.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 right-0 top-[100%] z-20 max-h-[30rem] overflow-auto rounded-b border-x border-b border-orange bg-white p-3 py-1 transition-[height] duration-300"
            ref={searchRef}
          >
            {results.map(r => {
              const label = startCase(
                {
                  collection: "Product Line",
                }[r.type] || r.type
              );
              if (r?.results?.length === 0) return null;
              return (
                <li key={r.type}>
                  <div className="my-5 font-bold uppercase underline underline-offset-[3px]">
                    {label}s
                  </div>
                  {r.results.map(rr => {
                    const url =
                      r.type === "product" || r.type === "collection"
                        ? `${rr.url}?q=${query}`
                        : `${rr.url}&q=${query}`;
                    return (
                      <li key={rr.id}>
                        <AppLink
                          to={`${url}`}
                          className="flex py-1.5 underline decoration-white underline-offset-[3px] transition duration-300 hover:decoration-orange"
                          onClick={() => {
                            setOpenSearch(false);
                            dispatch({ type: "closeModal" });
                          }}
                        >
                          {(r.type === "herb" || r.type === "product") && (
                            <span className="flex flex-col gap-1">
                              <div className="flex gap-1">
                                <span>
                                  <HtmlParser
                                    html={rr._highlightResult.title.value}
                                  />
                                </span>
                                {rr.petFriendly && (
                                  <>
                                    <Icon
                                      name="animal"
                                      className="-mt-0.5 w-3"
                                    />
                                    <span className="sr-only">
                                      Pet friendly
                                    </span>
                                  </>
                                )}
                              </div>
                              {(rr?._highlightResult?.pinyin?.value ||
                                rr?._highlightResult?.common?.value ||
                                rr.pinyin ||
                                rr.common ||
                                rr.title) && (
                                <span className="text-xs text-green">
                                  <HtmlParser
                                    html={`(${
                                      rr?._highlightResult?.pinyin?.value ||
                                      rr?._highlightResult?.common?.value ||
                                      rr.pinyin ||
                                      rr.common ||
                                      rr.title
                                    })`}
                                  />
                                </span>
                              )}
                            </span>
                          )}
                          {r.type !== "herb" && r.type !== "product" && (
                            <span className="text-left">
                              <HtmlParser
                                html={
                                  rr?._highlightResult.title.value || rr.title
                                }
                              />
                            </span>
                          )}
                        </AppLink>
                      </li>
                    );
                  })}
                </li>
              );
            })}
          </m.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default StoreSearch;
