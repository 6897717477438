import React, { useState, useMemo, useEffect, useRef } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import { m } from "framer-motion";
import MiniSearch from "minisearch";
import { useAppState } from "@state";

const SelectCustomer = ({ submittingState }) => {
  const [{ user }, dispatch] = useAppState();
  const dropdown = useRef();

  const options = [
    {
      id: user.info.id,
      first_name: user.info.firstName,
      last_name: user.info.lastName,
    },
    ...user.customers,
  ];

  const [expanded, setExpanded] = useState(false);
  const [submitting, setSubmitting] = submittingState || useState(false);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const miniSearch = useMemo(() => {
    const ms = new MiniSearch({
      fields: ["first_name", "last_name"], // fields to index for full-text search
      storeFields: ["first_name", "last_name"], // fields to return with search results
      searchOptions: {
        boost: { title: 2 },
        fuzzy: 0.1,
        prefix: true,
      },
    });
    ms.addAll(options);
    return ms;
  }, []);

  useEffect(() => {
    const res = miniSearch.search(query);
    setResults(res);
  }, [query]);

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    if (!submitting) setExpanded(false);
  }, [submitting]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative z-50 w-full" ref={dropdown}>
      <button
        type="button"
        className="-mb-px flex w-full items-center justify-between gap-3 rounded border border-orange p-3 text-left"
        onClick={() => {
          setExpanded(last => !last);
        }}
      >
        {user?.customer
          ? `${user?.customer?.lastName}, ${user?.customer?.firstName}`
          : "Select a Customer"}

        <Icon
          name="triangle"
          className={clsx("w-3 text-orange", {
            "": expanded,
            "rotate-180": !expanded,
          })}
        />
      </button>

      <m.div
        className="absolute z-50 w-full overflow-hidden bg-white sm:relative"
        initial={{ height: 0 }}
        animate={{ height: expanded ? "auto" : 0 }}
        transition={{
          height: { ease: [0.83, 0, 0.17, 1], duration: 0.35 },
        }}
      >
        <div className="rounded border border-orange p-3">
          <span className="-mb-2 text-sm font-bold">Select Customer</span>
          {options.length > 5 && (
            <div
              className={clsx(
                "relative mb-2 flex w-full items-center overflow-hidden border-b border-orange bg-white p-5 text-sm transition duration-300"
              )}
            >
              <input
                onChange={e => setQuery(e.target.value)}
                placeholder="Search"
                className={clsx(
                  "absolute inset-0 pb-1 pr-8 pt-5 placeholder:text-black"
                )}
              />
              <Icon
                name="search"
                className="pointer-events-none absolute bottom-0 right-1 top-4 z-10 h-4 w-4"
              />
            </div>
          )}

          <ul className="max-h-40 overflow-y-auto sm:max-h-24 lg:max-h-40">
            {options
              ?.filter(
                o => !results.length || results.map(r => r.id).includes(o.id)
              )
              .map((c, i) => {
                return (
                  <li
                    key={c.id || i}
                    className="my-3 text-sm text-orange hover:text-black focus:text-black"
                  >
                    <button
                      type="button"
                      className="inline-block w-full text-left"
                      onClick={() => {
                        setSubmitting(true);
                        if (!c.id) {
                          dispatch({
                            type: "setCustomer",
                            customer: user.info,
                          });
                          setSubmitting(false);
                          return;
                        }
                        const { id } = c;
                        fetch("/api/shopify/customer", {
                          method: "POST",
                          body: JSON.stringify({ id, token: user.token }),
                        })
                          .then(res => res.json())
                          .then(res => {
                            dispatch({
                              type: "setCustomer",
                              customer: res.data.customer,
                            });
                            setSubmitting(false);
                          })
                          .catch(err => {
                            setSubmitting(false);
                            // eslint-disable-next-line no-console
                            console.log(err);
                          });
                      }}
                    >
                      {c.last_name}, {c.first_name}{" "}
                      {user.info.id === c.id && `(Purchase for Myself)`}
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      </m.div>
    </div>
  );
};

SelectCustomer.defaultProps = {};

export default SelectCustomer;
