import React from "react";
import { Button } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
// import clsx from "clsx";
import { AnimatePresence } from "framer-motion";

const MobileHeader = ({ nav }) => {
  const [{ layout, user }, dispatch] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  if (nav) {
    return (
      <nav
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
      >
        <AnimatePresence>
          {!hideNav && (
            <ul className="absolute left-0 right-0 top-[100%] flex flex-col items-center gap-4 border-b border-t border-orange bg-white pb-6">
              {nav?.map(link => {
                const { type } = link;

                if (type === "productsDropdown") {
                  return (
                    <li key="products" className="relative">
                      <AppLink
                        to="/products/"
                        className="flex items-center justify-center gap-1 py-3 font-bold hover:border-orange"
                      >
                        <span className="underline decoration-orange underline-offset-4">
                          All Products
                        </span>
                      </AppLink>
                    </li>
                  );
                }

                if (type === "linksHeader") {
                  return (
                    <li key={link?.header?.url}>
                      <AppLink
                        to={link?.header?.url}
                        className="text-sm font-bold transition-opacity hover:opacity-70"
                      >
                        {link?.header?.text}
                      </AppLink>
                    </li>
                  );
                }

                return (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {user.level > link?.level && (
                      <li>
                        <AppLink
                          to={link?.linkObject?.url}
                          className="text-sm font-bold transition-opacity hover:opacity-70"
                        >
                          {link?.linkObject?.text}
                        </AppLink>
                      </li>
                    )}
                  </>
                );
              })}

              {/* user is not logged in */}
              {!user.type && (
                <>
                  <li>
                    <Button to="/login/">Log In</Button>
                  </li>
                  <li>
                    <Button to="/register/" outline>
                      Register
                    </Button>
                  </li>
                </>
              )}
              {/* user is logged in */}
              {user.type && (
                <>
                  <li>
                    <Button onClick={() => dispatch({ type: "logout" })}>
                      Logout
                    </Button>
                  </li>
                  <li>
                    <AppLink
                      className="text-sm font-bold text-orange"
                      to="/account/"
                    >
                      Account
                    </AppLink>
                  </li>
                  <li>
                    <button
                      onClick={() => dispatch({ type: "toggleCart" })}
                      type="button"
                      className="flex items-center gap-2"
                    >
                      {/* <Icon className="h-5 w-5 text-orange" name="orders" /> */}
                      <span className="text-sm font-bold text-orange">
                        Cart
                      </span>
                    </button>
                  </li>
                </>
              )}
              {/* <li>
              <div className="h-8 w-[2px] rounded bg-orange" />
            </li>
            <li>
              <AppLink className="text-sm font-bold text-orange">
                Sign Up
              </AppLink>
            </li> */}

              {/* {nav?.map(link => {
              const { text, url, uid } = link;
              return (
                <li key={uid}>
                  <AppLink to={url}>
                    <Text variant="label">{text}</Text>
                  </AppLink>
                </li>
              );
            })} */}
            </ul>
          )}
        </AnimatePresence>
      </nav>
    );
  }
  return null;
};

MobileHeader.defaultProps = {};

export default MobileHeader;
