import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useAppState } from "@state";
import getPrice from "@utils/getPrice";
import useAllIngredientsData from "@shopify/staticQueries/AllIngredientsQuery";
import useAllSymptomsData from "@shopify/staticQueries/AllSymptomsQuery";
import useAllElementsData from "@shopify/staticQueries/AllElementsQuery";
import useAllActionsData from "@shopify/staticQueries/AllActionsQuery";
import useAllOrgansData from "@shopify/staticQueries/AllOrgansQuery";
import useAllTonguesData from "@shopify/staticQueries/AllTonguesQuery";
import useAllPulsesData from "@shopify/staticQueries/AllPulsesQuery";
import useAllPattensData from "@shopify/staticQueries/AllPatternsQuery";
import useAllIndicationsDate from "@shopify/staticQueries/AllIndicationsQuery";
import useAllContraindicationsData from "@shopify/staticQueries/AllContraindicationsQuery";
import resolveProduct from "../dataResolvers/resolveProduct";

const query = graphql`
  query allProducts {
    products: allShopifyProduct {
      nodes {
        id
        storefrontId
        title
        handle
        totalInventory
        url: gatsbyPath(filePath: "/products/{ShopifyProduct.handle}")
        vendor
        variants {
          id
          storefrontId
          title: displayName
          inventoryQuantity
          sku
          image {
            url: src
            height
            width
          }
          selectedOptions {
            value
            name
          }
          price
        }
        image: featuredImage {
          url: src
          height
          width
        }
        metafields {
          key
          value
        }
        tags
        seo {
          description
          title
        }
        publishedAt
        collections {
          handle
          title
          url: gatsbyPath(filePath: "/collections/{ShopifyCollection.handle}")
          metafields {
            key
            value
          }
        }
      }
    }
  }
`;

const dataResolver = ({ products }) => products.nodes.map(resolveProduct);

const useDefaultsData = () => {
  const [{ user }] = useAppState();
  const data = useStaticQuery(query);
  const ingredients = useAllIngredientsData();
  const symptoms = useAllSymptomsData();
  const elements = useAllElementsData();
  const actions = useAllActionsData();
  const organs = useAllOrgansData();
  const tongues = useAllTonguesData();
  const pulses = useAllPulsesData();
  const patterns = useAllPattensData();
  const indications = useAllIndicationsDate();
  const contraindications = useAllContraindicationsData();

  const resolved = useMemo(
    () =>
      dataResolver(data).map(p => {
        // console.log(p);
        return {
          ...p,
          inStock: p.totalInventory > 0,
          variants: p.variants.map(v => ({
            ...v,
            price: v.price,
            retail:
              getPrice(v.price, user.price_mod, v.storefrontId, user.prices) ||
              null,
            inStock: v.inventoryQuantity > 0,
          })),
          actions: actions.filter(f => p?.actions?.includes(f.id)),
          symptoms: symptoms.filter(f => p?.symptoms?.includes(f.id)),
          elements: elements.filter(f => p?.elements?.includes(f.id)),
          herbs: ingredients.filter(
            f => p?.ingredients?.includes(f.id) && f.type === "Herb"
          ),
          organs: organs.filter(f => p?.organs?.includes(f.id)),
          tongues: tongues.filter(f => p?.tongues?.includes(f.id)),
          pulses: pulses.filter(f => p?.pulses?.includes(f.id)),
          patterns: patterns.filter(f => p?.patterns?.includes(f.id)),
          indications: indications.filter(f => p?.indications?.includes(f.id)),
          contraindications: contraindications.filter(f =>
            p?.formulaContraindications?.includes(f.id)
          ),
        };
      }),
    [user.price_mod, !!user.prices]
  );

  return resolved;
};

export default useDefaultsData;
