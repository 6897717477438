import React, { useEffect } from "react";
import propTypes from "prop-types";
import { PageSizeObserver } from "@base";

import { LazyMotion, m } from "framer-motion";
import { useAppState } from "@state";
import { getCookie, setCookie } from "@utils/cookies";
import navigate from "@utils/navigateWithParams";
import getParam from "@utils/getParam";

import Status from "@organisms/Status";
import Loading from "@organisms/Loading";
import Header from "@organisms/Header";
import Modal from "@organisms/Modal";
import Cart from "@organisms/Cart";
import GdprBanner from "@organisms/GdprBanner";
import Announcement from "@organisms/Announcement";
import DashboardSidebar from "@organisms/DashboardSidebar";

import Userback from "@organisms/Userback";

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, location }) => {
  const [{ user }, dispatch] = useAppState();
  const { token } = user;

  const dashboardAdminCheck = () => {
    // redirect if an admin gets to orders
    if (
      user.level > 3 &&
      (location.pathname.includes("/orders/") ||
        location.pathname.includes("/customers/"))
    ) {
      navigate("/account/");
    }
  };

  useEffect(() => {
    const usertoken = getCookie("usertoken");
    dispatch({ type: "retrieveCart" });

    // console.log(usertoken);
    if (!token && usertoken?.length > 10) {
      dispatch({ type: "setToken", token: usertoken });
    }
    if (!user.info && (token || usertoken?.length > 10)) {
      fetch("/api/shopify/user", {
        method: "POST",
        body: JSON.stringify({ token: token || usertoken }),
      })
        .then(res => res.json())
        .then(({ customer }) =>
          dispatch({
            type: "setUser",
            info: customer,
            userType: (customer.user_type || "consumer").toLowerCase(),
          })
        )
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
          setCookie("usertoken", null);
          if (location.pathname.match(/^\/account/)) {
            navigate("/login/");
          }
        });
    } else if (location.pathname.match(/^\/account/)) {
      navigate("/login/");
    }
  }, [token]);

  useEffect(() => {
    dashboardAdminCheck();
    if (user.level > 2) {
      fetch("/api/shopify/customers", {
        method: "POST",
        body: JSON.stringify({ token: user.token }),
      })
        .then(res => res.json())
        .then(res => {
          dispatch({ type: "setCustomers", customers: res.customers });
        });
    }
  }, [user.type]);

  useEffect(() => {
    dashboardAdminCheck();
  }, [location.pathname]);

  // set discount
  useEffect(() => {
    const discount = getParam("discount");
    if (discount) {
      dispatch({ type: "setDiscount", discount });
    }
    dashboardAdminCheck();
  }, []);

  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <m.div className="flex min-h-screen w-full flex-col overflow-x-hidden font-sans">
        <Cart />
        <PageSizeObserver />
        {!location.pathname.match("^/account") &&
          !location.pathname.match("^/invoice") && (
            <>
              <Announcement />
              <Header />
              <Status />
            </>
          )}
        {location.pathname.match("^/account") &&
          !location.pathname.match("^/invoice") && (
            <div
              key={`${user.type}--account`}
              className="flex h-full w-full flex-grow flex-col bg-gray-light lg:flex-row"
            >
              {!user.type && <Loading />}
              {user.type && (
                <>
                  <m.div
                    transition={{ duration: 0.3, type: "tween" }}
                    initial={{ opacity: 0, x: "-100%" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: "-100%" }}
                    className="relative w-full flex-shrink-0 lg:max-w-[300px] lg:flex-grow"
                  >
                    <DashboardSidebar location={location} />
                  </m.div>
                  <m.div
                    key={location.pathname}
                    initial={{ opacity: 0, x: 10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 10 }}
                    className="flex w-full flex-grow flex-col gap-5 bg-white p-5 sm:bg-transparent sm:p-10"
                  >
                    {children}
                  </m.div>
                </>
              )}
            </div>
          )}
        {!location.pathname.match("^/account") && (
          <div key={`${user.type}--content`}>{children}</div>
        )}
        <GdprBanner />
        <Modal />
        {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
        {/* uncomment if embedding facebook posts */}
        {/* <div id="fb-root" /> */}
        {/* TODO: enable chat widget before launch */}
        {/* {process.env.GATSBY_HUBSPOT_PORTAL_ID && <HubspotScript />} */}
      </m.div>
    </LazyMotion>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default DefaultLayout;
