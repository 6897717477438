import React from "react";
import { Icon, Container, Button } from "@atoms";
import { AppLink } from "@base";
import {
  MobileHeader,
  DropdownAccount,
  DropdownHeader,
  StoreSearch,
} from "@molecules";
import { useAppState } from "@state";
import { m, AnimatePresence } from "framer-motion";
import useHeaderData from "@staticQueries/HeaderQuery";

const Header = () => {
  const { nav } = useHeaderData();
  const [{ layout, user, cart }, dispatch] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "4",
    strokeLinecap: "round",
  };

  const triggerModal = () => {
    dispatch({
      type: "openModal",
      title: "Search modal",
      focusId: "headerSearch",
      description:
        "Search for Kan's product, symptons, pin yin name, herbs, and more.",
      // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
      content: React.memo(() => (
        <div className="mx-auto max-w-3xl px-2 pt-8 sm:pt-0">
          <span className="mb-2 inline-block text-white">Search:</span>
          <StoreSearch id="headerSearch" />
          <span className="mt-1 inline-block text-xs text-white">
            Enter a search term above to see results.
          </span>
        </div>
      )),
    });
  };

  return (
    <AnimatePresence initial>
      <m.header
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="relative z-[900] flex h-20 w-full items-center border border-b border-gray bg-offwhite p-2"
      >
        <Container>
          <div className="flex items-center justify-between">
            <AppLink
              to="/"
              className="flex shrink-0 flex-col gap-[0.125rem] text-justify"
            >
              <img
                src="/png/logo.png"
                alt="Kan Herb"
                className="h-auto w-40 mix-blend-multiply"
              />
            </AppLink>

            {/* nav */}
            <nav aria-label="Header navigation" className="flex gap-4">
              <ul className="hidden items-center gap-4 lg:flex">
                <DropdownHeader nav={nav} />

                <button
                  type="button"
                  aria-label="Open search modal"
                  onClick={triggerModal}
                >
                  <Icon name="search" className="w-4 text-orange" />
                </button>

                {user.type && (
                  <li>
                    <Button
                      onClick={() => dispatch({ type: "toggleCart" })}
                      type="button"
                      size="3xs"
                    >
                      <span className="flex items-center justify-center gap-2">
                        <span className="text-sm font-bold">Cart</span>

                        <span className="relative">
                          <Icon name="cart" className="w-[1.4rem]" />
                          <span className="absolute top-0 ml-[0.2rem] flex h-[0.68rem] w-[0.68rem] items-baseline justify-center overflow-hidden rounded-full bg-white p-[0.17rem] text-[0.5rem] text-orange">
                            {cart?.items
                              ?.map(i => i?.qty)
                              ?.reduce((a, b) => a + b, 0)}
                          </span>
                        </span>
                      </span>
                    </Button>
                  </li>
                )}

                {/* account */}
                <li>
                  <DropdownAccount user={user} />
                </li>
              </ul>

              <button
                type="button"
                aria-label="Open search modal"
                onClick={triggerModal}
                className="display md:hidden"
              >
                <Icon name="search" className="w-4 text-orange" />
              </button>

              <button
                type="button"
                className="flex lg:hidden"
                aria-label="Toggle mobile navigation"
                onClick={() => {
                  dispatch({
                    type: hideNav ? "showNav" : "hideNav",
                  });
                }}
              >
                {/* Hamburger Menu with animated SVG */}
                <span className="svg-icon h-6 w-6 text-black">
                  <svg viewBox="0 0 34 24">
                    <m.path
                      {...iconStyles}
                      variants={{
                        closed: { d: "M32 2L2 2" },
                        open: { d: "M24 22L2 2" },
                      }}
                    />
                    <m.path
                      {...iconStyles}
                      d="M32 12L2 12"
                      variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 },
                      }}
                      transition={{ duration: 0.2 }}
                    />
                    <m.path
                      {...iconStyles}
                      variants={{
                        closed: { d: "M32 22L2 22" },
                        open: { d: "M24 2L2 22" },
                      }}
                    />
                  </svg>
                </span>
              </button>
            </nav>
          </div>
        </Container>
        <MobileHeader nav={nav} />
      </m.header>
    </AnimatePresence>
  );
};

Header.defaultProps = {};

export default Header;
