import React from "react";
import { Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";
import clsx from "clsx";
import { m } from "framer-motion";

const AccordionItem = ({
  heading,
  copy,
  button,
  secondaryLink,
  i,
  state: _state,
}) => {
  const [open, setOpen] = _state;
  return (
    <div
      className={clsx({
        "bg-offwhite": open !== i,
        "bg-white": open === i,
      })}
    >
      <button
        type="button"
        className="flex w-full cursor-pointer list-none items-baseline justify-between p-5"
        aria-label={`Expand accordion containing information on ${heading}`}
        onClick={() => setOpen(last => (last === i ? -1 : i))}
      >
        <h4 className="text-left font-serif text-2xl">{heading}</h4>

        <Icon
          name="triangle"
          className={clsx("mr-2 h-6 w-6 shrink-0 text-orange transition", {
            "": open === i,
            "rotate-180": open !== i,
          })}
        />
      </button>
      <m.div
        className="overflow-hidden px-5"
        initial={{ height: 0 }}
        animate={{ height: open === i ? "auto" : 0 }}
        transition={{
          height: { ease: [0.83, 0, 0.17, 1], duration: 0.35 },
        }}
      >
        <Text variant="sm" richText className="mb-5 mt-2 text-gray-dark">
          {copy}
        </Text>

        {(button?.url || secondaryLink?.url) && (
          <div className="mb-5 mt-5 flex items-center gap-4">
            {button?.url && (
              <Button size="xs" to={button?.url}>
                <Text variant="sm">{button?.text}</Text>
              </Button>
            )}
            {secondaryLink?.url && (
              <AppLink
                to={secondaryLink?.url}
                className="font-bold underline decoration-orange decoration-2 underline-offset-4"
              >
                <Text variant="sm">{secondaryLink?.text}</Text>
              </AppLink>
            )}
          </div>
        )}
      </m.div>
    </div>
  );
};

AccordionItem.defaultProps = {};

export default AccordionItem;
