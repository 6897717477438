import React, { useEffect, useMemo, useState, useRef } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";
import MiniSearch from "minisearch";
import { AnimatePresence, m } from "framer-motion";
import { AppLink } from "@base";
import { useAppState } from "@state";

const SearchCustomer = () => {
  const [{ user }] = useAppState();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const miniSearch = useMemo(() => {
    const ms = new MiniSearch({
      fields: ["first_name", "last_name"], // fields to index for full-text search
      storeFields: ["first_name", "last_name"], // fields to return with search results
      searchOptions: {
        boost: { title: 2 },
        fuzzy: 0.1,
        prefix: true,
      },
    });
    ms.addAll(user.customers);
    return ms;
  }, [user.customer]);

  const search = q => {
    if (q?.length) {
      const searchResults = miniSearch.search(q);
      setResults(searchResults);
      setOpenSearch(true);
    }
  };

  useEffect(() => {
    if (query.length) {
      search(query);
    } else {
      setResults([]);
    }
  }, [query]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setOpenSearch(false);
      }
    }
    // Bind the event listener
    if (openSearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSearch]);

  return (
    <div className="relative w-full">
      <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="customerSearch" className="shrink-0 font-bold">
          Search Customers
        </label>
        <div className="relative w-full">
          <div className="relative flex w-full items-center overflow-hidden rounded border border-orange bg-white p-5 text-sm transition duration-300">
            <input
              id="customerSearch"
              name="customerSearch"
              onChange={e => setQuery(e.target.value)}
              // placeholder=""
              className={clsx(
                "absolute inset-0 h-full w-full p-3 pr-10 placeholder:text-black"
              )}
              onFocus={e => search(e.target.value)}
              ref={inputRef}
              type="text"
            />
            <Icon
              name="search"
              className="pointer-events-none absolute bottom-0 right-3 top-3 z-10 h-4 w-4"
            />
          </div>
          <AnimatePresence initial>
            {!!results.length && openSearch && (
              <m.ul
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute left-0 right-0 top-[calc(100%-1px)] z-20 max-h-40 overflow-auto rounded border border-orange bg-white p-3 py-1 transition-[height] duration-300"
                ref={searchRef}
              >
                {results.map(r => {
                  return (
                    <li
                      key={r.id}
                      className="my-3 text-sm text-orange hover:text-black focus:text-black"
                    >
                      <AppLink
                        to={`/account/customers/view/?id=${r.id}`}
                        className="text-left"
                      >
                        {r.last_name}, {r.first_name}
                      </AppLink>
                    </li>
                  );
                })}
              </m.ul>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default SearchCustomer;
