import camelCase from "lodash.camelcase";
import resolveCollection from "./resolveCollection";

const resolveProduct = p => {
  const types = [];
  const variantNames = p.variants.map(v => v.title).join("");
  if (variantNames.match(/Extract/)) {
    types.push("extract");
  }
  if (variantNames.match(/Tablet/)) {
    types.push("tablets");
  }
  const images =
    p.media
      ?.filter(m => m.mediaContentType === "IMAGE")
      ?.map(m => ({
        ...m.image,
        url: m.image.url
          // remove strings added to duplicate images
          .replace(
            /_[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
            ""
          )
          .replace(/\?v=.*/, ""),
      }))
      // filter for unique images
      .reduce((agg, img) => {
        // console.log(image.url);
        if (agg.map(m => m.url).includes(img.url)) {
          return agg;
        }
        return [...agg, img];
      }, []) || [];
  const pinyin = p.metafields.find(f => f.key === "chinese_name")?.value;
  return {
    ...p,
    ...p.metafields.reduce((agg, f) => ({ ...agg, [f.key]: f.value }), {}),
    meta: {
      metaPageTitle:
        p.seo.title ||
        (pinyin ? `${pinyin} / ${p.title} | Kan Herb Company` : p.title),
      metaDescription:
        p.seo.description || p.description?.replace(/(<([^>]+)>|\n)/gi, ""),
      metaFeaturedImage: images[0] ? { url: images[0].url } : undefined,
    },
    images,
    organs: p?.organs === "string" ? JSON.parse(p?.organs) : [],
    indications:
      typeof p?.indications === "string" ? JSON.parse(p?.indications) : [],
    formulaContraindications:
      typeof p?.formula_contraindications === "string"
        ? JSON.parse(p?.formula_contraindications)
        : [],
    elements: typeof p?.elements === "string" ? JSON.parse(p?.elements) : [],
    tongues: typeof p?.tongues === "string" ? JSON.parse(p?.tongues) : [],
    symptoms: typeof p?.symptoms === "string" ? JSON.parse(p?.symptoms) : [],
    actions: typeof p?.actions === "string" ? JSON.parse(p.actions) : [],
    ingredients:
      typeof p?.ingredients === "string" ? JSON.parse(p.ingredients) : [],
    relatedProducts:
      typeof p?.relatedProducts === "string"
        ? JSON.parse(p?.relatedProducts)
        : [],
    pusles: typeof p?.pulses === "string" ? JSON.parse(p?.pulses) : [],
    patterns: typeof p?.patterns === "string" ? JSON.parse(p?.patterns) : [],
    types,
    variants: p.variants
      .map(v => ({
        ...v,
        type: v.selectedOptions.find(o => o.name === "Type")?.value,
        inStock: v.inventoryQuantity > 0,
      }))
      .sort(
        (a, b) =>
          parseInt(
            a.selectedOptions
              .find(o => o.name === "Size")
              ?.value.split(" ")[0] || 0,
            10
          ) -
          parseInt(
            b.selectedOptions
              .find(o => o.name === "Size")
              ?.value.split(" ")[0] || 0,
            10
          )
      )
      .sort((a, b) => a.type?.localeCompare(b.type)),
    productLine: p.collections
      .map(resolveCollection)
      .find(c => c.type === "Product Line"),
    collectionHandles: p.collections.map(c => c.handle),
    inStock: p.totalInventory > 0,
    ...p.metafields.reduce(
      (agg, meta) => ({
        ...agg,
        [camelCase(meta.key)]:
          meta.key === "pattern_identifications"
            ? JSON.parse(meta.value)
            : meta.value,
      }),
      {}
    ),
  };
};

export default resolveProduct;
