import React from "react";
import { Image, Text } from "@atoms";
import { AppLink } from "@base";

const CardArticle = ({ url, title, image, metaDescription, types }) => {
  return (
    <AppLink to={url} className="flex gap-8 text-left">
      <div className="relative aspect-[148/142] h-auto w-32 shrink-0 items-center">
        <Image image={image} fill />
      </div>

      <div className="space-y-2">
        <h3 className="font-serif text-1.5xl">{title}</h3>
        {!!types?.length && (
          <span className="inline-block text-sm uppercase text-orange">
            {types?.[0]?.title}
          </span>
        )}
        <Text variant="sm">{metaDescription}</Text>
        <span className="inline-block font-bold underline decoration-orange decoration-2 underline-offset-4">
          More
        </span>
      </div>
    </AppLink>
  );
};

CardArticle.defaultProps = {};

export default CardArticle;
