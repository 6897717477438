import React, { useState } from "react";
import { m } from "framer-motion";
import useAllCollectionsData from "@shopify/staticQueries/AllCollectionsQuery";
import { AppLink } from "@base";
import { Icon } from "@atoms";
import { useAppState } from "@state";
import DropdownNav from "./DropdownNav";

const Col = ({ col, user }) => {
  const { type } = col;
  const [animation, setAnimation] = useState("hidden");
  const productLines = useAllCollectionsData().filter(
    c => c.type === "Product Line"
  );
  const container = {
    hidden: {},
    show: {},
  };

  const menu = {
    hidden: { opacity: 0, pointerEvents: "none", y: "1rem" },
    show: { opacity: 1, pointerEvents: "auto", y: 0 },
  };
  if (type === "productsDropdown") {
    return (
      <m.li
        key={col?.uid}
        initial="hidden"
        className="relative"
        variants={container}
        animate={animation}
        onHoverStart={() => {
          setAnimation("show");
        }}
        onHoverEnd={() => {
          setAnimation("hidden");
        }}
        onClick={() => {
          setAnimation("hidden");
        }}
      >
        <AppLink
          to="/products/"
          className="flex items-center justify-center gap-0.5 py-3 font-bold transition-opacity hover:opacity-70"
        >
          <span className="text-sm">{col?.text}</span>
          <Icon name="triangle" className="h-4 w-4 rotate-180 text-orange" />
        </AppLink>
        <DropdownNav
          menu={menu}
          headingLink={{ url: "/products", text: "View All " }}
          links={col?.productLinks?.map(pl =>
            productLines.find(c => c.handle === pl)
          )}
        />
      </m.li>
    );
  }

  if (type === "linksHeader") {
    return (
      <m.li
        key={col?.uid}
        initial="hidden"
        className="relative"
        variants={container}
        animate={animation}
        onHoverStart={() => {
          setAnimation("show");
        }}
        onHoverEnd={() => {
          setAnimation("hidden");
        }}
        onClick={() => {
          setAnimation("hidden");
        }}
      >
        <AppLink
          to={col?.headingLink?.url}
          className="flex items-center justify-center gap-0.5 py-3 font-bold transition-opacity hover:opacity-70"
        >
          <span className="text-sm">{col?.headingLink?.text}</span>
          <Icon name="triangle" className="h-4 w-4 rotate-180 text-orange" />
        </AppLink>
        <DropdownNav menu={menu} links={col?.children} />
      </m.li>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user.level > col?.level && (
        <li>
          <AppLink
            to={col?.linkObject?.url}
            className="text-sm font-bold transition-opacity hover:opacity-70"
          >
            {col?.linkObject?.text}
          </AppLink>
        </li>
      )}
    </>
  );
};

const DropdownHeader = ({ nav }) => {
  const [{ user }] = useAppState();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {nav?.map((col, i) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Col key={i} col={col} user={user} />;
      })}
    </>
  );
};

DropdownHeader.defaultProps = {};

export default DropdownHeader;
