import React from "react";
import { Button, ButtonAlt, Icon } from "@atoms";
import { AppLink } from "@base";
import clsx from "clsx";

const Socials = ({ socials }) => {
  return (
    <li className="flex items-center gap-2">
      {socials?.map((s, i) => {
        const { platform, accounturl } = s;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <AppLink
            to={accounturl}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className="flex h-6 w-6 items-center justify-center rounded-full bg-orange"
          >
            <Icon
              name={platform}
              className="duration-400 text-highlight h-4 w-4 transition hover:opacity-70 md:h-3 md:w-3"
              fitHeight={platform === "facebook"}
            />
          </AppLink>
        );
      })}
    </li>
  );
};

const ComponentName = ({ links, socials }) => {
  return (
    <ul className="flex w-full flex-col gap-5 gap-y-12 sm:flex-row sm:gap-16">
      {links.map((col, i) => {
        const isOnlyLinks = Array.isArray(col);
        const { header, links: _links } = col;

        if (isOnlyLinks) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <ul className="space-y-5">
                {col.map((link, ii) => {
                  const { linkObject, style } = link;

                  if (style === "button") {
                    return (
                      // eslint-disable-next-line react/no-array-index-key, no-unsafe-optional-chaining
                      <li key={linkObject?.url + ii}>
                        <Button to={linkObject?.url} size="sm">
                          {linkObject?.text}
                        </Button>
                      </li>
                    );
                  }

                  if (style === "underlined") {
                    return (
                      // eslint-disable-next-line react/no-array-index-key, no-unsafe-optional-chaining
                      <li key={linkObject?.url + ii}>
                        <ButtonAlt to={linkObject?.url}>
                          {linkObject?.text}
                        </ButtonAlt>
                      </li>
                    );
                  }

                  return (
                    // eslint-disable-next-line react/no-array-index-key, no-unsafe-optional-chaining
                    <li key={linkObject?.url + ii}>
                      <AppLink
                        to={linkObject?.url}
                        className={clsx(
                          "text-sm transition-opacity hover:opacity-70",
                          {
                            "font-bold uppercase": style === "uppercase",
                          }
                        )}
                      >
                        {linkObject?.text}
                      </AppLink>
                    </li>
                  );
                })}
                {socials && i === (links?.length || 0) - 1 && (
                  <Socials socials={socials} />
                )}
              </ul>
            </li>
          );
        }

        return (
          <li key={header?.url}>
            <span className="mb-5 inline-block">
              <AppLink to={header?.url} className="text-sm font-bold uppercase">
                {header?.text}
              </AppLink>
            </span>
            {!!_links?.length && (
              <ul className="space-y-4">
                {_links?.map((link, ii) => (
                  // eslint-disable-next-line react/no-array-index-key, no-unsafe-optional-chaining
                  <li key={link?.url + ii}>
                    <AppLink
                      to={link?.url}
                      className="text-sm transition-opacity hover:opacity-70"
                    >
                      {link?.text}
                    </AppLink>
                  </li>
                ))}
                {socials && i === (links?.length || 0) - 1 && (
                  <Socials socials={socials} />
                )}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ComponentName;
