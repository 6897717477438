import getVariant from "./getVariant";

const getSubtotal = ({ cart, variants }) => {
  let subtotalInCents = 0;
  cart.items.forEach(item => {
    const variant = variants.find(v => getVariant(v, item));
    subtotalInCents += variant.retail * 100 * (item.qty || item.quantity);
  });
  return (subtotalInCents / 100).toFixed(2);
};

export default getSubtotal;
