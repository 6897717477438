import React, { useState, useEffect } from "react";
import { m } from "framer-motion";
import useAnnouncementData from "@staticQueries/AnnouncementQuery";
import { Text, Icon, Container, ButtonAlt } from "@atoms";

const Announcement = () => {
  const { enabled, heading, copy, button } = useAnnouncementData();
  const [show, setShowing] = useState(false);

  useEffect(() => {
    if (enabled) {
      setTimeout(() => {
        const seen =
          window.localStorage.getItem("announcement") === heading + copy;
        if (seen) {
          setShowing(false);
        } else {
          setShowing(true);
        }
      }, 3000);
    }
  }, []);

  const variants = {
    show: {
      height: "auto",
      pointerEvents: "auto",
    },
    hide: {
      height: 0,
      pointerEvents: "none",
    },
  };

  return (
    <m.div
      initial="hide"
      variants={variants}
      animate={show ? "show" : "hide"}
      className="relative overflow-hidden bg-black pr-4 text-white"
    >
      <Container padding className="relative">
        <div className="flex max-w-xs flex-col gap-2 py-4 sm:max-w-[none] sm:flex-row sm:items-center sm:justify-between sm:gap-3">
          <div>
            <Text
              variant="h6"
              className="mb-3 inline-block font-serif text-xl leading-tight sm:text-2xl"
            >
              {heading}
            </Text>
            <Text className="inline-block text-[16px] font-medium leading-tight">
              {copy}
            </Text>
          </div>
          {button?.url && (
            <div className="flex-shrink-0">
              <ButtonAlt to={button.url}>{button.text}</ButtonAlt>
            </div>
          )}
        </div>
        <button
          type="button"
          className="absolute bottom-0 right-1 top-0 flex items-center justify-center"
          onClick={() => {
            window.localStorage.setItem("announcement", heading + copy);
            setShowing(false);
          }}
        >
          <span className="sr-only">close</span>
          <Icon
            name="close"
            className="h-6 w-6 text-white opacity-50 transition duration-300 hover:opacity-100 sm:h-4 sm:w-4"
          />
        </button>
      </Container>
    </m.div>
  );
};

export default Announcement;
