import React from "react";
import clsx from "clsx";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const Breadcrumbs = ({ className: _className, crumbs }) => {
  return (
    <div
      className={clsx(
        "relative flex flex-wrap items-baseline gap-x-5 gap-y-3",
        _className
      )}
    >
      {crumbs?.map((crumb, i) => {
        const { uid, uri, title } = crumb;
        if (i !== crumbs.length - 1) {
          return (
            <div key={uid} className="flex items-baseline gap-4">
              <AppLink to={uri}>
                <Text className="underline decoration-orange decoration-2 underline-offset-4 duration-300 hover:opacity-70">
                  {title}
                </Text>
              </AppLink>
              <Icon
                name="triangle"
                className="flex-0 h-auto w-5 translate-y-1 rotate-90 text-orange"
              />
            </div>
          );
        }
        return (
          <span key={uid} className="pointer-events-none">
            {title}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
