import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { kebabCase } from "lodash";

const query = graphql`
  query AllIngredients {
    ingredients: allIngredient {
      nodes {
        id
        common
        pinyin
        type
      }
    }
  }
`;

const dataResolver = ({ ingredients: { nodes } }) =>
  nodes?.map(n => ({
    ...n,
    common: JSON.parse(n.common)?.[0],
    handle: kebabCase(JSON.parse(n.common)?.[0]),
    url: `/products?herb=${parseInt(n.id.split("/").pop(), 10)}`,
  }));

const useDefaultsData = () => {
  const data = useStaticQuery(query);
  const resolved = useMemo(() => dataResolver(data), []);
  return resolved;
};

export default useDefaultsData;
