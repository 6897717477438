import { setCookie, getCookie } from "@utils/cookies";

const reducer = (state, action) => {
  const { type, qty, rx, variant, price, id, mode, note, discount } = action;
  let saved_cart = "";
  let cartItems = state.items;

  switch (type) {
    case "toggleCC":
      return {
        ...state,
        sendCC: !state.sendCC,
      };
    case "setMode":
      return {
        ...state,
        mode,
      };
    case "clearCart":
      setCookie(
        "cart",
        JSON.stringify({
          items: [],
          note: "",
        })
      );
      return {
        ...state,
        items: [],
        note: "",
        showing: false,
      };
    // case "logout":
    //   setCookie(
    //     "cart",
    //     JSON.stringify({
    //       items: [],
    //       note: "",
    //     })
    //   );
    //   return {
    //     ...state,
    //     items: [],
    //     note: "",
    //     showing: false,
    //   };
    case "updateCartQty":
      cartItems = cartItems.map(item => {
        const nItem = { ...item };
        if (nItem.id === id) {
          nItem.qty = qty;
        }
        return nItem;
      });
      setCookie(
        "cart",
        JSON.stringify({
          items: cartItems,
        })
      );
      return {
        ...state,
        items: cartItems,
      };
    case "updateRx":
      cartItems = cartItems.map(item => {
        const nItem = { ...item };
        if (nItem.id === id) {
          nItem.rx = rx;
        }
        return nItem;
      });
      setCookie(
        "cart",
        JSON.stringify({
          items: cartItems,
        })
      );
      return {
        ...state,
        items: cartItems,
      };
    case "setNote":
      return {
        ...state,
        note,
      };
    case "toggleCart":
      return {
        ...state,
        showing: !state.showing,
      };
    case "setDiscount":
      return {
        ...state,
        discount,
      };
    case "closeCart":
      return {
        ...state,
        showing: false,
      };

    case "retrieveCart":
      saved_cart = getCookie("cart");
      if (saved_cart?.length) {
        return { ...state, ...JSON.parse(saved_cart), showing: false };
      }
      return state;
    case "removeCartItem":
      cartItems = cartItems.filter(item => item.id !== id);
      setCookie(
        "cart",
        JSON.stringify({
          items: cartItems,
        })
      );
      return {
        ...state,
        items: cartItems,
      };
    case "addToCart":
      cartItems = [...cartItems, { id: variant, qty, price }].reduce(
        (cart, v) => {
          const nCart = [...cart];
          const existingItemIdx = cart.findIndex(c => c.id === v.id);
          if (cart[existingItemIdx]) {
            nCart[existingItemIdx].qty =
              parseInt(v.qty, 10) + parseInt(nCart[existingItemIdx].qty, 10);
            return nCart;
          }
          return [...cart, v];
        },
        []
      );
      setCookie(
        "cart",
        JSON.stringify({
          items: cartItems,
        })
      );
      return {
        ...state,
        items: cartItems,
        showing: true,
      };
    case "updateCart":
      setCookie(
        "cart",
        JSON.stringify({
          items: action.items,
        })
      );
      return {
        ...state,
        items: action.items,
        errors: action.errors,
        showing: false,
      };
    case "clearErrors":
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};

const initialState = {
  items: [],
  showing: false,
  mode: "order",
  discount: null,
  errors: [],
};

export default {
  reducer,
  initialState,
};
