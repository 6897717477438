import { navigate } from "gatsby";

const navigateWithParams = uri => {
  const params = window.location.href.split("?")[1];
  if (params) {
    navigate(`${uri}?${params}`);
  } else {
    navigate(uri);
  }
};

export default navigateWithParams;
