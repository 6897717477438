import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import clsx from "clsx";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";

const Carousel = ({
  children,
  indicators,
  maxVisible,
  className: _className,
  floatIndicators,
  hideInactive,
  hideButtons,
  autoplay,
  inverse,
  minSlides,
}) => {
  const [pause, setPause] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });
  const timeout = useRef(null);

  useEffect(() => {
    if (autoplay && !pause) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      setTimeout(() => {
        setCurrentSlide(s => {
          if (s < slides.length - 1) {
            return s + 1;
          }
          return 0;
        });
      }, 5000);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [autoplay, pause, currentSlide]);

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  // from tailwind config
  const screens = {
    xxs: { max: "350px" }, // for super small screens
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1600px", // larger than 15" macbook pro
    xxl: "2000px",
  };

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    const { x: velocity } = info.velocity;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount || velocity < -400) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount || velocity > 400) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: minSlides || 1,
        md: minSlides || 1,
        lg: minSlides || 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    let getWidth;
    if (carouselContainer.current) {
      getWidth = requestAnimationFrame(() => {
        if (carouselContainer.current) {
          setSlideWidth(carouselContainer.current.clientWidth);
        }
      });
    }
    return () => {
      if (getWidth) cancelAnimationFrame(getWidth);
    };
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    let getSlides;
    if (carouselContainer.current) {
      getSlides = requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
    return () => {
      if (getSlides) cancelAnimationFrame(getSlides);
    };
  }, [windowWidth]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={clsx("relative w-full", _className)}
      >
        {/* prev button */}
        {!hideButtons && slideCount > 1 && (
          <div className="translate-y-50 absolute -left-12 bottom-0 top-0 z-10 hidden transform items-center justify-center md:flex">
            <button
              className={clsx(
                "group flex items-center justify-center rounded-full p-2 transition duration-300",
                {
                  "bg-white/50 text-white": !inverse,
                  "bg-orange/60 text-white hover:bg-orange-dark": inverse,
                  "pointer-events-none opacity-0": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="triangle"
                className="relative right-0 h-4 w-4 -translate-x-0.5 -rotate-90 transform transition duration-300"
              />
            </button>
          </div>
        )}
        <m.div
          animate={carouselControls}
          className="flex"
          transition={{ duration: 0.5, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag={slideCount > 1 ? "x" : false}
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
          dragDirectionLock
          onMouseEnter={() => setPause(true)}
          onMouseLeave={() => setPause(false)}
        >
          {slides.map((slide, i) => (
            <div
              key={slide.uid}
              className={clsx("relative duration-300", {
                "opacity-20":
                  (i < currentSlide || i + 1 > currentSlide + visibleSlides) &&
                  !hideInactive,
                "opacity-0":
                  i < currentSlide ||
                  (i + 1 > currentSlide + visibleSlides && hideInactive),
              })}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </m.div>
        {/* next button */}
        {!hideButtons && slideCount > 1 && (
          <div className="absolute -right-12 bottom-0 top-0 z-10 hidden items-center justify-center md:flex">
            <button
              className={clsx(
                "group flex items-center justify-center rounded-full p-2 transition duration-300",
                {
                  "bg-white/50 text-white": !inverse,
                  "bg-orange/60 text-white hover:bg-orange-dark": inverse,
                  "pointer-events-none opacity-0":
                    currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="triangle"
                className="relative right-0 h-4 w-4 rotate-90 transition duration-300"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {indicators && slideCount > 1 && (
        <ul
          className={clsx("flex items-center justify-center", {
            "absolute bottom-4 left-1 right-1 z-10": floatIndicators,
            "mt-4": !floatIndicators,
          })}
        >
          {slides.map((slide, i) => (
            <li key={slide.uid}>
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={clsx(
                  "mx-1 block h-2 w-2 rounded-full transition duration-300",
                  {
                    "opacity-50": currentSlide !== i,
                    "bg-white": floatIndicators,
                    "bg-orange": !floatIndicators,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
  hideInactive: true,
};

export default Carousel;
